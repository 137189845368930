.y-axis-arrow-label1 {
  display: block;
  width: 170px;
  height: 10px;
  background: url("./../../../assets/Torque-Arrow.svg") 100% 100% no-repeat;


}

.y-axis-arrow-label2 {
  display: block;
  width: 170px;
  height: 10px;
  background: url("./../../../assets/Mas-Air-Flow-Arrow.svg") 100% 100% no-repeat;


}

.y-axis-arrow-label3 {
  display: block;
  width: 170px;
  height: 10px;
  background: url("./../../../assets/Engine-Speed-Arrow.svg") 100% 100% no-repeat;


}

.y-axis-arrow-label4 {
  display: block;
  width: 170px;
  height: 10px;
  background: url("./../../../assets/Mainfold-Pressure-Arrow.svg") 100% 100% no-repeat;


}

.text-color-label2 {
  color: #DBAC06;
}

.text-color-label4 {
  color: #75777C;

}