@import "../../../scss/colors";

.self-test-container {
  padding: 0rem 3rem 3rem 3rem;
  margin-top: 20px;
  margin: 60px 50px;
  border: 1px solid #eeeeee;
}
.self-test-container .select-container {
  max-width: unset;
}
.select-container-holder {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: relative;
  bottom: 80px;
  left: 85px;
  height: 10px;
}
.self-test-card-container {
  width: 200px;
  text-align: center;
  .self-test-heading {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .self-test-card-box {
    background: #ebf7fd;
    // border: 1px solid #cfcfd0;
    border-radius: 10px;
    height: 20rem;
    padding: 50px 0;
  }
  .self-test-card-wrapper {
    padding: 0 50px;
    height: 100%;
    overflow-y: auto;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    // &::-webkit-scrollbar { /* Chrome */
    //   display: none;
    // }
  }
  .self-test-card {
    margin-bottom: 20px;
    background-color: #fff;
    padding: 8px 12px;
    border-radius: 3px;
    box-shadow: 0.5px 0.5px rgba(14, 77, 164, 0.2);
    font-size: 14px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: 0px 4px 8px rgba(14, 77, 164, 0.2);
      cursor: pointer;
    }
  }
}
