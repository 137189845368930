.component-system-history-maintenance-profile-table {
  padding: 30px 60px 90px;

  thead,
  th {
    background-color: #ffffff;
    padding: 20px;
    text-align: start;
    &:nth-child(1) {
      width: 15% !important;
      border-radius: 20px 20px 0px 0px !important;
    }

    &:nth-child(2) {
      width: 10% !important;
    }

    &:nth-child(3) {
      width: 45% !important;
    }

    &:nth-child(4) {
      width: 30% !important;
    }
  }
  td {
    text-align: start;
  }
  tr {
    box-shadow: none !important;

    &:last-child {
      & td {
        &:last-child {
          border-radius: 0px 0px 20px 20px !important;
        }
      }
    }
  }

  .view-details-from-table {
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
  }

  .pagination-container {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1100px) {
  .component-system-history-maintenance-profile-table {
    padding: 30px 30px 90px;
  }
}

@media screen and (min-width: 1700px) {
  .component-system-history-maintenance-profile-table {
    padding: 30px 60px 90px;
  }
}
