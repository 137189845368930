.warranty-rate-card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #353536;
  margin-bottom: 10px;
}
.warranty-rate-card-container {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 14px;
  padding: 20px;
  width: 400px;

  .warranty-rate-card-calander-container {
    display: flex;
  }

  .warranty-rate-card-calander {
    background: #e2edff;
    border-radius: 30px;
    font-size: 12px;
    margin-right: 6px;
    font-style: normal;
    font-weight: 500;
    color: #000000;
    line-height: 30px;
    padding: 0px 11px;
    cursor: pointer;

    &.active {
      background: #446eb8;
      color: #ffffff;
    }
  }
}
.warranty-rate-card-text {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #353536;

  .warranty-rate-card-value {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #353536;
  }
}
