.saved-filter {
  label {
    cursor: pointer;
  }
}
.saved-filter-container {
  background-color: #fff;
  border-radius: 30px 30px 30px 30px;
  width: 100%;
  padding: 25px;
  min-height: 1000px;

  .top-button-container {
    margin-right: 3px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    height: 40px;

    p {
      background-color: #446eb8;
      height: 100%;
      margin-top: 0px;
      color: white;
      padding: 0px 5px 0px 5px;
      font-size: 13px;
      border-radius: 5px;
    }

    img {
      position: relative;
      top: 8px;
    }
  }
}

.top-button-container-icons {
  display: flex;
  gap: 10px;
}

.empty-checkbox {
  height: 10px;
}

.saved-filters {
  display: contents;
}

.top-button-container {
  &.heading-container {
    img {
      position: fixed;
    }

    &.arrow-icon {
      position: fixed;
    }
  }
}

.left-side-content {
  display: flex;
  width: 570px;
  justify-content: flex-start;
  align-items: center;

  img {
    position: relative;
  }
}

.dropdown_content {
  .select-container {
    .heading-container {
      width: 128px;
      height: 39px;
      background: #f0f0f3;
      border-radius: 5px;

      .arrow-icon {
        position: relative;
        left: -4px;
        top: 2px;
      }
    }

    .select-options-container {
      .select-option {
        .option-value {
          background: #000;
          border: 1px solid #fff;
          color: #fff;
          width: 135px;
        }

        img {
          top: 0px;
        }
      }
    }
  }
}

.arrow-icon-up {
  transform: rotate(180deg);
}

.search-field-container {
  z-index: 1;
  .search_image {
    top: 3px !important;
    left: 30px;
  }

  .search_field {
    width: 318px;
    height: 36px;
    padding: 10px 10px 10px 50px;
    border: 1px solid #c4c4c4;
    border-radius: 30px;

    input {
      display: flex;
    }
  }
}

// Filter Card

.saved-filter-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  margin-top: 14px;
  gap: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  justify-content: space-around;
}

.saved-filter-card-container {
  width: 275px;
  min-height: 195px;
  background: #ffffff;
  border: 1px solid #c9ced7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px;

  &.active {
    border: 1px solid;
  }
}

.saved-filter-card-container-full {
  width: 275px;
  min-height: 185px;
  background: #ffffff;
  border: 1px solid #c9ced7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px;
}

.saved-filter-card-header {
  display: flex;
  width: 100%;
  gap: 200px;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.saved-filter-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 20px;
  border-radius: 1px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: auto;
  &.D {
    background-color: #b5c9ff;
  }
  &.P {
    background: #cdffff;
  }
}

.sign-dropdownlist {
  padding: 0px 15px;
  position: relative;
  left: 139px;
  width: 91px;
  height: 110px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  top: 4px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;

  .sign-dropdown-btn {
    border: 1px solid #ffffff;
    width: 93px;
    padding: 6px;
    background-color: #000;
    color: #ffffff;
  }
}

.saved-filter-content {
  margin-bottom: 5px;
  height: 75px;
}

.saved-filter-description {
  margin-top: 10px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  .double-arrow {
    margin: 0px 2px 0px 2px;
  }
}

.saved-filter-dropdown {
  width: 235px;
  display: flex;
  gap: 186px;
  padding: 2px;
  cursor: pointer;

  &.close {
    margin-bottom: 30px;
  }
}

.saved-filter-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}

.saved-filter-labeled-checkbox {
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 4px;
  height: 10px;

  &.main {
    margin-bottom: 20px;
  }
}

.saved-filter-checkbox-label {
  width: 203px;
}

.saved-filter-checkbox {
  margin-bottom: 15px;

  &.main {
    position: relative;
  }
}

.viewall-close {
  img {
    width: 20px;
    height: 20px;
  }
}

.edit-button {
  margin-top: 10px;
}

.btn-primary-blue {
  &.saved-filter {
    position: relative;
    left: 180px;
    margin: 3px;
    padding: 0px;
    width: 64px;
    height: 21px;
  }

  .edit-icon {
    position: relative;
    top: 1px;
    left: 5px;
  }
}

.saved-filter-dropdown-viewall {
  position: relative;
  bottom: 4px;
  font-size: 10px;
  line-height: 14px;
  color: #353536;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

//Popup

.popup {
  right: 7px;
  position: relative;
  background-color: #fff;
  bottom: 10px;
}

.popup-wrapper {
  min-height: 380px;
  padding: 10px;

  .popup-close {
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .popup-header {
    height: 20px;
  }

  .btn-primary-blue {
    &.saved-filter {
      position: relative;
      bottom: 25px;
      left: 186px;
      margin: 3px;
      padding: 2px;
      width: 61px;
      height: 23px;
    }
  }

  .popup-dropdownlist {
    position: relative;
    left: 187px;
    bottom: 13px;
  }

  .popup-dropdownlist-save {
    padding: 0px 15px;
    position: relative;
    left: 139px;
    bottom: 27px;
    width: 91px;
    height: 110px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;

    .popup-dropdown-btn {
      border: 1px solid #ccc;
      width: 93px;
      padding: 6px;
      background-color: #fff;
      cursor: pointer;
    }
  }

  .popup-content {
    margin-top: 10px;

    .popup-dropdown {
      display: contents;

      .popup-label {
        display: flex;
        gap: 30px;
        margin-top: 3px;
        margin-left: 10px;
        font-size: 14px;
        color: #000000;
        cursor: pointer;

        .popup-label-content {
          width: 180px;
        }
      }

      .popup-checkbox {
        display: flex;
        gap: 5px;
        font-size: 14px;

        &.active {
          color: #446eb8;
        }

        .popup-label-checkbox {
          display: flex;
          gap: 5px;
        }

        .popup-checkbox-content {
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        input[type="checkbox"] {
          position: relative;
          left: 19px;
          display: flex;
          opacity: 0;
          z-index: 1;
          width: 14px;
          height: 14px;
          margin-top: 4px;
        }

        input[type="checkbox"]+span {
          top: 4px;
        }

        input[type="checkbox"]:checked+span {
          border: none;
        }

        input[type="checkbox"]:checked+span::before {
          color: #446eb8;
          border: 1.5px solid #446eb8;
          width: 12px;
        }
      }
    }
  }
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+span {
  display: inline-block;
  position: relative;
  top: 7px;
  width: 14px;
  height: 14px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  border: 1.5px solid #000;
  cursor: pointer;
  margin-right: 4px;
}
input[type="checkbox"]:checked + span::before {
  content: url(../../../assets/checkbox-tick-white.svg);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11px;
  width: 13px;
}

input[type="checkbox"]:disabled+span {
  border: 1.5px solid #a2a2a2;
}

.saved-filter-container,
.remotet,
.maintainance-on-demand-filter,
.content-box {
  input[type="checkbox"]:checked + span::before {
    content: url(../../../assets/checkbox-tick.svg);
    height: 12px;
    width: 12px;
  }
}

.popup-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  .saved-filters-date {
    width: 120px;
  }

  .input-value-saved-filters-date {
    font-size: 12px !important;
    height: 30px;
  }
}

// ViewAll
.viewall-wrapper {
  width: 271px;

  .viewall-close {
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .viewall-header {
    height: 20px;
  }

  .btn-primary-blue {
    &.saved-filter {
      position: relative;
      bottom: 25px;
      left: 186px;
      margin: 3px;
      padding: 2px;
      width: 61px;
      height: 23px;
    }
  }

  .viewall-dropdownlist {
    position: relative;
    left: 187px;
    bottom: 13px;
  }

  .viewall-dropdownlist-save {
    padding: 0px 15px;
    position: relative;
    left: 139px;
    bottom: 27px;
    width: 91px;
    height: 110px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

    .viewall-dropdown-btn1 {
      border: 1px solid #ccc;
      width: 93px;
      padding: 6px;
      background-color: #fff;
    }

    .viewall-dropdown-btn2 {
      border: 1px solid #ccc;
      width: 93px;
      padding: 6px;
      background-color: #fff;
    }

    .viewall-dropdown-btn3 {
      border: 1px solid #ccc;
      width: 93px;
      padding: 6px;
      background-color: #fff;
    }
  }
}

.saved-filter-dropdown-label {
  display: flex;
  gap: 49px;
  margin-top: 3px;
  margin-left: 10px;
  font-size: 14px;
  color: #000000;
}

//viewall

.viewall-content {
  position: relative;
  right: 9px;

  .viewall-dropdown {
    display: contents;

    .viewall-label {
      display: flex;
      gap: 49px;
      margin-top: 3px;
      margin-left: 10px;
      color: #000000;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;

      .viewall-label-content {
        width: 180px;
      }
    }

    .viewall-checkbox {
      margin-left: 19px;
      display: flex;
      font-size: 14px;
      gap: 5px;

      .viewall-checkbox-label {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.viewall-content--minh {
  height: 78px;
  overflow: hidden;
  margin-bottom: 5px;
  cursor: default;
}

.saved-filter-test-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 5px 0px 5px 5px;

  // text-align: center;
  // width: 260px;
  // height: 20px;
  // font-style: normal;
  // font-weight: 600;
  // font-size: 16px;
  // line-height: 20px;
  // color: #000000;
  // margin: 5px 0px 5px 5px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.plus-icon-button {
  cursor: pointer;
  z-index: 1;
}