.diagnostics-container {
  margin-top: 20px;
}

.full-width-diagnostics-container-s {
  margin-top: 60px;
}

.sec-tabs-container-s {
  padding: 0px;
  position: relative;
  left: 20px;
  margin-top: 20px;
}

.btn-primary-sec-tabs {
  padding: 4px;
  text-decoration: none;
  border-bottom: 3px solid orange;
  margin: 10px;
  font-size: 14px;
  font-weight: 600;
}

.btn-secondary-sec-tabs {
  padding: 4px;
  text-decoration: none;
  margin: 10px;
  font-size: 14px;
  font-weight: 600;
}
