@import "../../scss/colors";

.header {
  padding: 10px 1.2rem;
  background-color: $primary;
  color: $white;
  font-size: $font14;
  z-index: 11;
  width: 100%;
  position: sticky;
  top: 0;
  height: 10vh;
  // box-shadow: 1px 1px 5px #112135, -1px -1px 5px #112337;
  box-shadow: 5px 5px 10px #c3c5c8, -5px -5px 10px #cbcdd0;
  // height: 70px;
}

.header-logo {
  img {
    width: 100%;
  }

  cursor: pointer;
  // margin: 5px 0px;
  padding: 10px;
}

.icon-and-image-container {
  // padding: 10px 20px;
  font-family: "Open Sans" !important;
  cursor: pointer;
}

.icon {
  height: 20px;
  width: 20px;
}

.navigation-container {
  width: 450px;
}

.header-search {
  width: 18rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;

  input {
    padding: 10px 10px 10px 30px;
    border-radius: 10px;
    outline: none;
    background: $white;
    color: $black;
    border: 1px solid $white;
    height: 45px;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      opacity: 0.5;
    }
  }
}

.search-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.navigation-container {
  width: 450px;
}

.navigation-container-header {
  width: 450px;
}

.header-Card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  width: auto;
  height: fit-content;

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #041f3e;
    text-align: left;
    margin: 5px;
  }

  img {
    margin: 5px;
  }

  &:hover {
    cursor: pointer;
    background: $tangerine;

    h6 {
      color: $white;
    }

    img {
      filter: invert(100%);
    }
  }
}

.header-Card-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $tangerine;
  border-radius: 10px;
  width: auto;
  height: fit-content;

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $white;
    ;
    text-align: left;
    margin: 5px;
  }

  img {
    margin: 5px;
    filter: invert(100%);
  }

  &:hover {
    cursor: pointer;
    background: $tangerine;

    h6 {
      color: $white;
    }

    img {
      filter: invert(100%);
    }
  }
}

.top-headerbar {
  margin: 0px 0px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  gap: 5px;
  flex-wrap: wrap-reverse;
  z-index: 9;

  .header-leftside {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    flex: 1 1 auto;
    flex-wrap: wrap-reverse;
    cursor: pointer;

    img {
      margin: 10px;
    }
  }

  .header-BarCard {
    display: flex;
    align-items: center;
    flex: 2 1 auto;
    flex-wrap: wrap;
    row-gap: 3px;
    column-gap: 10px;
    justify-content: space-between;
  }

  .search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 6px;
    cursor: pointer;
  }
}

@media only screen and (max-height: 767.5px) {
  .header-logo {
    img {
      // position: relative;
      // top: -5px;
    }
  }

  .navigation-container {
    width: 30%;

    .AdminButton {
      width: 20%;
    }

    .text {
      padding-top: 6px;
    }
  }

  .navigation-container-header {
    width: 30%;

    .text {
      padding-top: 6px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .top-headerbar {
    .header-BarCard {
      justify-content: flex-start;
    }
  }
}