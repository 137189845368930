.realtime-gauge-select {
  .option-value {
    padding: 10px 16px;
  }
  .option-box0 {
    border-bottom: 1px solid white;
  }
}
.chart-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .unit-container {
    // flex: 1.9 1;
    display: flex;
    align-items: center;
    margin-top: 41px;
    justify-content: center;
    font-family: "Digi";
    font-weight: 300;
    font-size: 36px;
    line-height: 64px;
    color: #353536;
    letter-spacing: -0.065px;
  }
  .chart-text-container {
    p {
      font-family: sans-serif;
      font-size: 12px;
      color: rgb(53, 53, 54);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 194px;
    }
  }
}

.realtime-graph {
  .graph {
    > div {
      box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
      margin-left: 10px;
      // border: 2px solid #cecece;
      border-radius: 10px;
      background-color: #ffff;
    }
  }
}
.real-time-large-graphs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.gas-pedal {
  cursor: pointer;
}
.gas-pedal-large,
.brake-pedal-large {
  margin-top: 10px;
  width: 70%;
  padding: 10px;
}

.real-time-close-icon {
  cursor: pointer;
  z-index: 100;
  width: 0px;
  img {
    width: 20px;
    height: 15px;
  }
}
