.go-button {
  max-width: 120px;
  height: 40px;
  align-self: flex-end;
}
.details-button {
  font-size: 12px;
  padding: 5px;
}
.btn-primary-blue {
  padding: 10px;
  align-self: flex-end;
  //font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  &.get-dtc{
    padding: 9px;
  }
}
.predicted-dtc-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #353536;
  margin-bottom: 25px;
  margin-top: 20px;
}

.low-rul {
  display: inline-block;
  vertical-align: middle;
  width: 55px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #353536;
  transform: rotate(-90deg);
  text-align: center;
}

.high-rul {
  display: inline-block;
  vertical-align: middle;
  width: 55px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #353536;
  transform: rotate(-90deg);
  text-align: center;
  margin-left: -15px;
}

.selected-dtc-name-number {
  padding-top: 40px;
}

.left-arrow-wrapper,
.right-arrow-wrapper {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #e2edff;
  // box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
  //   0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: auto;
  }
}

.dtc-numbers-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-left: -15px;
}

.dtc-numbers-ul {
  margin: 0;
  padding: 0;
}

.dtc-numbers-li {
  display: inline-block;
  cursor: pointer;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  // line-height: 25px;
  line-height: 6px;
  padding: 15px 16px;
  color: #000000;
  background-color: #e2edff;
  box-shadow: 0px 4px 8px rgba(14, 77, 164, 0.2);
  box-sizing: border-box;
  border: 1px solid #fff;
  // filter: drop-shadow(0px 4px 8px rgba(14, 77, 164, 0.2));
  &.active {
    font-weight: 400;
    color: #ffffff;
    background-color: #446eb8;
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.fault-trend-select {
  margin-right: 70px;
}
.fault-trend-input {
  width: 130px;
  // text-align: right;
  height: 35px;
  font-size: 14px;
  align-self: flex-end;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
  padding: 10px;
}

.fault-trend-show-details {
  width: 100px;
  margin-left: 20px;
  font-size: 12px !important;
  padding: 7px 10px;
}

.dtc-name-number {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
  color: #353536;
}

.fault-description-wrapper,
.fault-sub-system-wrapper {
  font-size: 14px;
  line-height: 1.25;
  color: #000000;
  padding: 20px;
  .heading {
    font-weight: 600;
    font-size: 14px;
  }
  .pointers {
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.pointers-wrapper {
  margin: 0;
  margin-top: 10px;
}

.fault-description-wrapper {
  background-color: #f5ffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  border-radius: 20px 20px 0px 0px;
}

.sub-systems {
  background-color: #fafffd;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.remaining-useful-life {
  background-color: #f8fffe;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 20px 20px;
}

.rul-items {
  margin-bottom: 15px;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .item-name {
    width: 25%;
    text-align: left;
  }

  .item-value {
    width: 10%;
    text-align: left;
  }

  .go-button {
    width: auto;
    padding: 5px 15px;
    height: 35px;
    align-self: flex-end;
  }
}

.analyticgraph--top {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 10px 0px;

  span {
    font-weight: 600;
    margin: 0px 5px;
    span {
      font-weight: 400;
    }
  }
}
.analyticgraph--bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 0px;
}
.fault-trend-modal {
  .modal-container {
    width: 566px;
    overflow-x: hidden;
    border-radius: 20px;
    .modal-close {
      img {
        position: relative;
        left: 10px;
        top: -7px;
      }
    }
    .modal-body {
      position: relative;
      left: 17px;
    }
  }
}

.fault-trend-container {
  padding: 20px;
  .heading-container {
    border: 1px solid #efeeee;
  }
}
.fault-trend-filter {
  position: relative;
  top: 10px;
  left: 40px;
}
.large {
  position: relative;
  left: 20px;
}

@media screen and (min-width: 1100px) {
  .fault-description-wrapper,
  .fault-sub-system-wrapper {
    font-size: 14px;
  }
  .predicted-dtc-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  .fault-description-wrapper,
  .fault-sub-system-wrapper {
    font-size: 16px;
  }
  .predicted-dtc-text {
    font-size: 18px;
  }
}

@media screen and (max-height: 767px) {
  .fault-trend-modal {
    .modal-container {
      max-height: 90vh;
    }
  }
}
