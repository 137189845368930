.criteria {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.when-criteria-data {
  margin-bottom: 10px;
}

.is-is-not-analysis-table {
  padding: 30px 60px 90px;

  thead,
  th {
    background-color: #ffffff;
    padding: 20px;

    &:nth-child(1) {
      width: 10% !important;
      border-radius: 20px 0px 0px 0px !important;
    }

    &:nth-child(2) {
      width: 30% !important;
    }

    &:nth-child(3) {
      width: 31% !important;
    }

    &:nth-child(4) {
      width: 31% !important;
      border-radius: 0px 20px 0px 0px !important;
    }
  }

  tr {
    box-shadow: none !important;

    &:last-child {
      & td {
        &:last-child {
          border-radius: 0px 0px 20px 0px !important;
        }

        &:nth-child(1) {
          border-radius: 0px 0px 0px 20px !important;
        }
      }
    }
  }
}
