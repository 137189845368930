.maintenance-performance{
    margin-top: 30px;
    padding: 15px 40px;
    background: #FDF9EA;
    border: 1px solid #FCF1CC;
    border-radius: 2px;
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li{
            // text-transform: capitalize;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.location-table{
    padding: 30px 12px 12px 12px;
    //margin-bottom: 15px;

    thead, th {
        background-color: #fff;
        color:#000;
        width: 25% !important; 
        padding: 10px;
        font-size: 14px;
        &:last-child{           
            border-radius: 0px 20px 0px 0px !important;
        }
    }

    .pagination-container{
        margin-bottom: 30px;
    }
}