.DataVisualization-Chart {
    align-items: center;
    padding: 30px;
    .graph-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 21.79px;
    }

    .failure-details {
        font-size: 16px;
        font-weight: 600;
        line-height: 21.79px;
        margin-bottom: 0px;

    }

    .data-container {
        margin-left: 40px;
        margin-top: 0px;
    }

    .data {
        display: flex;


        p {
            margin-right: 100px;
            font-size: 16px;
            font-weight: 600;
            line-height: 21.79px;
            margin-bottom: 0px;

        }

        span {
            margin-left: 5px;
            font-weight: lighter;

        }
    }

    .info-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;

        .graph-inner {
            display: inline-flex;
            justify-content: flex-end;
            // background: #E3F5E3;
            padding: 0px 8px;
            padding-left: 20px;
            margin-bottom: 10px;
            margin-right: 40px;

            .inner {
                display: flex;
                align-items: center;
                gap: 4px;
                margin-right: 20px;

                .box {
                    height: 3px;
                    width: 12px;
                    // background-color: green;
                }

            }
        }
    }

}