.btn-driver-summary {
  margin: 15px 0 15px 0;
}

.maintenance-help-wrapper {
  .maintenance-help {
    // background-color: #e3f5e3;
    padding: 10px;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    font-weight: 600;
  }
}

.service-help-wrapper-driver-behaviour {
  display: flex;
  flex-direction: row-reverse;
}
.service-help-driver-behaviour {
  font-size: 14px;
  .btn-secondary-blue {
    font-size: 12px;
    width: auto;
    padding: 10px;
    margin: 10px 20px 10px 10px;
  }
}

.service-help {
  background-color: #ffffff;
  box-shadow: none;
  color: #353536;
  font-size: 14px;
  .btn-secondary-blue {
    //border:1px solid #E58817;
  }
}

.vehicle-usage-charts {
  .large-charts {
    width: 80%;
    position: relative;

    .modal-close {
      position: absolute;
      top: 77px;
      right: 25px;
      cursor: pointer;
      z-index: 5;

      &.driver-behaviour {
        top: 77px;
      }
      &.componentsubsystem {
        top: 35px;
        right: 20px;
      }
    }

    .highcharts-figure {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }
  }
}

// .operational-analytics {
//   justify-content: center;
// }

.operational-analytics-filter {
  position: relative;
  left: 45px;
  top: 12px;
  .filter-label {
    align-items: start !important;
  }
  .filter-wrapper {
    input[type="checkbox"] + span {
      top: 4px !important;
    }
  }
}

.discard-card-modal-close {
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}
