.highcharts-figure {
    width: 48%;
    //height:500px;
    //margin-bottom: -100px;
    margin: 0.5rem;
    &.full-width{
        width: 100%;
    }
}
.highcharts-title{
    font-size: 12px !important;
}
.remote-graph-name{
    padding: 10px;
    display: flex;
    justify-content: center;
}
