@import "../../scss/colors";
@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,500;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
* {
  box-sizing: border-box;
}
.signup {
  overflow: hidden;
  height: 100%;
  display: flex;
  color: white;
  margin: 0;
  min-height: 100vh;
  align-items: center;
  background-color: #ffffff;

  .signup__Form {
    overflow: auto;
    flex: 1;
    font-family: "Open Sans";
    width: 50%;
    background-color: #ffffff;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .login--logo {
      position: relative;
      right: 12px;
      max-width: 261px;
      max-height: 52px;
    }
    .signup__rightside__container {
      padding: 0px 40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #363636;

      .signup__Form--title {
        p {
          font-style: normal;
          font-weight: 100;
          font-size: 20px;
          line-height: 1.5;
          color: #494949;
        }
      }
      .signup__Form--error {
        color: red;
        text-align: center;
      }
      .signup__Form--footer {
        span {
          display: grid;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          margin: 15px auto;
        }
        a {
          text-decoration: none;
          color: #085fda;
          &:hover {
            color: rgba(68, 110, 184, 1);
          }
        }
        b {
          cursor: pointer;
        }
      }

      .signup__Form--btn {
        margin: 10px auto;

        .btn-primary-blue {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 10px 20px;
          border-radius: 30px;
          background: #0147ab;
          width: 160px;
          cursor: pointer;
          &:hover {
            background-color: #1458b8;
          }
        }
      }
      .signup__Form--inputs {
        width: 83%;

        input {
          padding: 10px 30px;
          border-radius: 35px;
          width: 100%;
        }

        .textareabox {
          position: relative;
          overflow: clip;
        }
        textarea[required] + label {
          position: absolute;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          line-height: 1.5;
          left: 10px;
          top: 10px;
          width: 95%;
          color: #747577;
          padding: 0px 20px;
        }
        textarea[required] + label:after {
          content: " so that we can process your request and give you the concerned level of access to you";
          color: #c4c4c4;
        }
        textarea[required]:invalid + label {
          display: inline-block;
        }
        textarea[required]:valid + label {
          display: none;
        }
        textarea {
          border-radius: 35px;
          width: 100%;
          resize: none;
          overflow: auto;
          border: 0.5px solid #8e9093;
          line-height: 1.5;
          outline: none;
          padding: 10px 30px;
          //margin: 5px 0px;
          color: #747577;
          font-size: 14px;
          position: relative;

          &::placeholder {
            font-weight: 600;
          }

          &::-webkit-scrollbar {
            width: 2px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
          }
        }
        span {
          padding-left: 15px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.5;
          color: #f23732;
        }
        .phnumber__container {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          border-radius: 35px;
          .signup__Form--phnumber {
            display: flex;
            flex-direction: row;
            margin-bottom: 2px;
          }
          span {
            display: inline-block;
          }
        }
      }
    }
  }
}
.adminverify__pop {
  width: 100%;
  padding: 30px;
  &--inputs {
    width: 100%;
    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5;
      color: rgba(116, 117, 119, 1);
    }
    button {
      border-radius: 5px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 30px;
    }
  }
  .adminverify__pop--title {
    p {
      margin: 0px 0px 10px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 1.5;
      color: #0147ab;
    }
  }
}
.setpwd__Form {
  padding: 20px;
  width: 100%;
  &--inputs {
    width: 100%;
    input {
      width: 100%;
      border-radius: 5px;
      padding: 5px !important;
    }
    button {
      border-radius: 5px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 30px;
    }
  }
  .setpwd__Form--title {
    margin-bottom: 10px;
    p {
      margin: 0px 0px 10px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 1.5;
      color: $primary;
    }
  }
  .setpwd__Form--btn {
    display: flex;
    justify-content: center;
    button {
      width: 100%;
    }
  }
}
