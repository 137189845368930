.layout__container {
  flex: 1;
  width: 100%;
  background-color: #eff6ff;
  // background-image: url(../../assets/login_left_bg.png);
  min-height: 100%;
  display: flex;
  align-self: stretch;
}

.layout__container--content {
  display: flex;
  justify-content: center;
  background: #eff6ff;
  height: fit-content;

  .layout__container--logo {
    margin: 20px;
    max-width: 287px;
    max-height: 57px;
    align-self: left;
    flex: 1;
  }

  .layout__container--image {
    position: relative;
    top: 80px;
    width: 100%;
    height: 75%;
    align-self: center;
    flex: 1;
  }

  .layout__container--testimonial {
    align-self: center;
    color: #363636;
    flex: 1;

    p {
      margin: 0px;
      font-family: "Exo";
      font-style: italic;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1;
      text-shadow: 0px 2px 4px rgba(24, 39, 75, 0.12),
        0px 4px 4px rgba(24, 39, 75, 0.08);

      span {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        text-align: end;
        margin-top: 10px;
      }

      span::before {
        background-color: #363636;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 3%;
      }
    }
  }
}

.logout-dropdown {
  position: absolute;
  right: 0px;
  top: 50px;
  border: 1px solid black;
  border-radius: 10px;
  list-style-type: none;
  min-width: 17%;
  background-color: black;
  cursor: pointer;
  z-index: 999999 !important;

  p {
    border-bottom: 1px solid white;
    margin: 0px;
    text-align: center;
    cursor: pointer;
  }

  .user-name {
    padding: 5px 5px 5px 3px;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }

    button {
      padding: 0px;
      padding-left: 5px;
      right: 5px;
    }
  }

  span {
    position: relative;
    bottom: 13px;
  }

  .admin-dashboard-text {
    button {
      right: 5px;
    }
  }

  .logout {
    border-bottom: none;

    button {
      right: 31px;
    }
  }

  .help {
    button {
      right: 39px;
    }
  }

  button {
    color: white;
    position: relative;
    // right: 5px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    padding: 0px;
  }
}
.modBtns {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.saveInput {
  display: block;
  border: 1px solid rgb(196, 196, 196);
  width: 100%;
  line-height: 1.6;
  border-radius: 6px;
}

.discardBtns {
  display: flex;
  justify-content: end;
}
.error-text {
  color: red;
  font-size: 14px;
}

@media screen and (max-width: 400px) {
  .layout__container {
    display: none !important;
    img {
      display: none;
    }
  }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
  .layout__container {
    display: none !important;
    img {
      display: block !important;
    }
  }
}
