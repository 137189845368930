.quality-table{
    padding: 30px 12px 12px 12px;
    //margin-bottom: 15px;

    thead, th {
        background-color: #fff;
        color:#000;
        width: 20% !important; 
        padding: 10px;
        font-size: 14px;
        &:last-child{           
            border-radius: 0px 20px 0px 0px !important;
        }
    }

    .pagination-container{
        margin-bottom: 30px;
    }
}