$small: 300px;
$medium: 900px;
.breadcrumb-container {
  position: relative;
  left: 20px;
  top: 5px;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 10px;
  span a {
    color: #27374a;
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;

  }
}

// @media screen and (min-width: 1700px) {
//   .breadcrumb-container {
//     left: 60px;
//   }
// }
