.page-wrapper {
  padding: 0px 10px;
  // width: 97%;
  height: 100%;
  min-height: 70vh;
  background-color: #fff;
  &.vehicle-usage {
    padding: 20px;
  }
}

.page-heading {
  font-size: 18px;
  color: #353536;
  margin-bottom: 20px;
}

.page-filter {
  display: flex;
  justify-content: space-between;
  width: 160px;
  margin-bottom: 10px;
  &.vehicle-usage {
    flex-direction: column;
  }
}

.page-options-container {
  display: flex;
  justify-content: flex-start;
}

.page-content-header {
  justify-content: space-between;
  margin-top: 10px;
  .date-picker {
    margin: 17px 0px 0px 30px;
  }
}
.page-content-header--filter {
  display: flex;
  justify-content: end;
}
.page-chart-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 85%;
}

.chip-holder {
  margin-top: 20px;
}

.value-input {
  margin-right: 20px;
}

.date-selector {
  padding: 10px 10px;
  width: 150px;
  //background: url("../../assets/datepicker.png") 90% 50% no-repeat;
  background-color: #fff;
  color: #112236;
  border: 1px solid #112236;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.date-selector:focus {
  outline: none;
}
.error-msg {
  border-color: red;
}
.legend-wrapper {
  display: flex;
  justify-content: flex-end;
  // margin-bottom: 30px;
}

.legend {
  display: flex;
  justify-content: flex-end;
}

.color-box {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 5px;

  &.green {
    background-color: #0574a6;
  }
  &.yellow {
    background-color: #09a1a1;
  }
  &.red {
    background-color: #dd359a;
  }
  &.pink {
    background-color: #dd359a;
  }
  &.blue {
    background-color: #0574a6;
  }
  &.black {
    margin-right: 2px;
    margin-left: -3px;
    background-color: #000000;
  }
  &.darkgreen {
    margin-right: 2px;
    margin-left: -7px;
    background-color: #045e5e;
  }
  &.maroon {
    margin-right: 2px;
    margin-left: -3px;
    background-color: #700746;
  }
}

.line-box {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 2px;
  margin-right: 5px;

  &.green {
    background-color: #0574a6;
  }

  &.yellow {
    background-color: #09a1a1;
  }

  &.red {
    background-color: #dd359a;
  }

  &.pink {
    background-color: #dd359a;
  }
  &.blue {
    background-color: #0574a6;
  }
  &.black {
    background-color: #000000;
  }
  &.darkgreen {
    background-color: #045e5e;
  }
  &.maroon {
    background-color: #700746;
  }
}

.green-legend,
.yellow-legend,
.red-legend {
  text-align: left;
  margin-top: 10px;
  margin-right: 10px;
}

.color-legend {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
}

.time-icon {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.react-datepicker-wrapper {
  width: auto !important;
  align-self: flex-end;
}

.vehicle-usage-charts {
  align-items: center;

  .highcharts-figure {
    width: 48%;
    margin: 0.5rem;
  }
}

.nav-icons {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

@media screen and (min-width: 1100px) {
  .page-wrapper {
    padding: 0px 10px;
  }
}

@media screen and (min-width: 1700px) {
  .page-wrapper {
    padding: 0px 60px;
  }
}
