.MuiStep-root {
  .MuiSvgIcon-root {
    color: "red !important",
      // fill: "#FF7104",
      text {
      display: "none";
    }
  }
  .MuiStepIcon-text {
    text {
      visibility: "hidden";
    }
  }
  .btnn {
    background-color: "orange";
    color: "#fff";
  }
  .Mui-active {
    color: red !important;
  }
}
.MuiStepIcon-root.Mui-active {
  color: red !important;
}
.top {
  display: flex;
  justify-content: space-between;
}
span.MuiButton-endIcon.MuiButton-iconSizeMedium {
  margin-left: 0px;
  margin-right: 0px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.makeStyles-button-6.MuiButton-outlined:hover {
  background: #eff1f4 !important;
  font-size: 24px;
}

.btnn img {
  border-style: none;
  //background: #ECECF9;
  border-radius: 50%;
  padding: 6px;
  height: 38px;
}

select {
  width: 70%;
  height: 38px;
  padding: 4px;
  border-radius: 5px;
}
.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

input#Build\ date {
  padding: 4px;
  margin-right: 6px;
  /* width: 111%; */
}
.rul-container {
  display: flex;
  margin-bottom: 8px;
}
.rul-container .metric-box {
  position: relative;
  top: 0px;
}
span.dtcs-values {
  display: flex;
  justify-content: center;
  color: #e58817;
  font-size: 14px;
  font-weight: 600;
}
button.optBtn {
  padding: 3px !important;
  color: #fff;
  background: #446eb8;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}
// .filter-FL-Container{
//     padding:0px;
// }
// .filter-FL-Container{
//     border-radius:30px;
// }
.MuiPaper-rounded {
  border-radius: 30px;
}
.generalDt {
  display: inline-flex;
}
.select-conatiner-active,
.select-conatiner {
  position: relative;
  display: inline-block;
}

.blink {
  position: absolute;
  top: 7px;
  right: 6px;
  color: #dd359a;
  animation: blink-animation 1s infinite;
  width: 8px;
  height: 8px;
  background: #dd359a;
  border-radius: 5px;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.select--checkbox {
  width: 70%;
  input[type="checkbox"] {
    display: inline-block;
    background-color: #fff;
  }
}
