@import "../../../../scss/colors";

.onboard_page_wrapper {
  padding: 0px 0px 30px !important;
}

.onboard-container {
  margin: 13px 30px;
  position: relative;
}

.onboard-dtc-wrapper-s {
  padding: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

  &.current {
    background-color: #F5FFFF;
    border-radius: 0px 30px 0px 0px;
    border: 1px solid #F2F2F2;
  }

  &.pending {
    background-color: #FAFFFD;
    border-radius: none;
    border: 1px solid #F2F2F2;
  }

  &.historical {
    background-color: #F8FFFE;
    border-radius: 0px 0px 30px 30px;
    border: 1px solid #F2F2F2;
  }
}

.non-onboard-dtc-wrapper-s {
  padding: 0px;
  box-shadow: 0px;
}

.onboard-dtc-container-s {
  background-color: #FFF9FF;
  width: 165px;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  margin: 10px;
  margin-bottom: 30px;

  &.Transmission {
    background-color: #FFF9FF;
  }

  &.ABS {
    background-color: #FFF8E0;
  }
}

.onboard-dtc-header-s {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  width: 160px;
  text-transform: capitalize;
}

.onboard-dtc-type-s {
  position: relative;
  top: 40px;
  transform: rotate(-90deg);
  font-size: 14px;
  width: 284px;
}

.onboard-dtc-params-s {
  display: flex;
  width: 800px;
  cursor: pointer;
}

.onboard-dtc-param-s {
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 1px 10px -2px rgb(24 39 75 / 12%), 0px 4px 8px -4px rgb(24 39 75 / 14%);
  border-radius: 4px;
  width: 120px;
}

.onboard-dtc-right-container-s {
  padding: 10px;
  margin-top: 24px;
}

.onboard-dtc-right-container-content-s {
  display: flex;
  margin: 35px 15px;
}

.onboard-dtc-description-s {
  //font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 450px;
  padding: 20px;
  display: flex;
  margin-left: 15px;
}

.onboard-dtc-time-s {
  //font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 200px;
  padding: 20px;
}

.onboard-dtc-arrow-s {
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 20px;
}





.onboard-dtc-right-section {
  //   background-color: $card-light-purple;
}

.onboard-dtc-hdr {
  padding-left: 30px;
  font-size: 18px;
  font-weight: 600;
}

.onboard-dtc-type {
  //   transform: rotate(270deg);
  //   width: 345px;
  padding: 15px 30px 10px;
  font-size: 14px;
}

.onboard-dtc-card {
  background-color: $card-purple;
  padding: 15px 30px;

  .onboard-dtc-param {
    background-color: $white;
    padding: 12px 30px;
    box-shadow: $dtcCardBoxShadow;
    border-radius: 4px;
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.onboard-dtc-abs-wrap {
  background-color: $card-light-pink;
}

.onboard-dtc-history-wrap {
  background-color: $card-light-red;
}

.onboard-dtc-right-wrap {
  justify-content: space-between;
  width: 80%;
  width: -webkit-fill-available;
}

.onboard-dtc-description,
.onboard-dtc-update-date {
  align-items: center;
  padding-left: 8px;
  font-size: 14px;
}

.dtc-category-container {
  div {
    display: flex;
  }

  &.current {
    background-color: #F5FFFF;
  }

  &.pending {
    background-color: #FAFFFD;
  }

  &.historical {
    background-color: #F8FFFE;
  }
}

.onboard-dtc-wrap.transmission {
  .onboard-dtc-card {
    background-color: #FFF9FF;
  }
}

.onboard-dtc-wrap.abs {
  .onboard-dtc-card {
    background-color: #fdfaf1;
  }
}

.onboard-dtc-description {
  max-width: 22rem;
  min-width: 22rem;
  font-size: 14px;
}

.onboard-dtc-details {
  align-items: center;

  button {
    background-color: $primary;
    color: $white;
    padding: 10px 15px;
  }
}

.onboard-filter {
  justify-content: flex-end;
  margin-top: 30px;
  position: absolute;
  right: 10px;
  top: -20px;
}

@media screen and (min-width: 1400px) {
  .onboard-dtc-type {
    font-size: 16px;
  }

  .onboard-dtc-description {
    font-size: 16px;
  }

  .onboard-dtc-description,
  .onboard-dtc-update-date {
    font-size: 16px;
  }
}