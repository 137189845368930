.driver-summary-wrapper {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  position: relative;
  .driver-summary-card {
    height: 60px;
    box-shadow: 0px 8px 8px -6px rgb(24 39 75 / 12%),
      0px 8px 8px -8px rgb(24 39 75 / 14%);
    border-radius: 2px;
    width: 100%;
    max-width: 150px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    img {
      width: 20px;
    }
    &:hover {
      .driver-summary-tooltip {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .driver-summary-text {
    font-size: 12px;
    max-width: 150px;
  }
  .driver-summary-tooltip {
    position: absolute;
    background-color: #000;
    color: #ffffff;
    border-radius: 5px;
    display: none;
    bottom: -15px;
    left: 50%;
    padding: 3px 8px 8px 8px;
    font-size: 12px;
    z-index: 10;
    white-space: nowrap;
    box-shadow: 0px 8px 8px -6px rgb(24 39 75 / 12%),
      0px 8px 8px -8px rgb(24 39 75 / 14%);
    > div {
      padding-top: 5px;
    }
  }
  &:nth-child(4n) {
    .driver-summary-tooltip {
      left: unset;
      right: 50%;
    }
  }
}

.summary-driver-behaviour {
  background-color: #f7f8fa;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  color: #353536;
  margin-top: 20px;
}

.driver-behaviour-summary-wrapper {
  background-color: #ffffff;
  padding: 20px 20px 50px 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  .service-help-wrapper {
    padding: 0px;
  }

  .page-chart-container.vehicle-usage-charts {
    justify-content: flex-start;
  }
}

.service-help .btn-primary.view-button {
  padding: 7px 15px;
}
.pictorialBtn {
  font-size: 12px !important;
  padding: 10px;
}
