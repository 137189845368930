@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?yl2k9s");
  src: url("fonts/icomoon.eot?yl2k9s#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?yl2k9s") format("truetype"),
    url("fonts/icomoon.woff?yl2k9s") format("woff"),
    url("fonts/icomoon.svg?yl2k9s#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bxs_user:before {
  content: "\e900";
  color: #c4c4c4;
}
.icon-cil_expand-right:before {
  content: "\e901";
}
.icon-clarity_car-solid:before {
  content: "\e902";
  color: #c4c4c4;
}
.icon-copyright-1 .path1:before {
  content: "\e903";
  color: rgb(255, 255, 255);
}
.icon-copyright-1 .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Data-Intervel:before {
  content: "\e905";
}
.icon-Date-Picker:before {
  content: "\e906";
}
.icon-bx_time:before {
  content: "\e907";
}
.icon-bxs_help-circle:before {
  content: "\e908";
  color: #fff;
}
.icon-cil_expand-left:before {
  content: "\e909";
}
.icon-cil_expand-right1:before {
  content: "\e90a";
}
.icon-ic_baseline-check-box-outline-blank:before {
  content: "\e90b";
}
.icon-ic_baseline-notifications:before {
  content: "\e90c";
  color: #fff;
}
.icon-ic_outline-check-box:before {
  content: "\e90d";
}
.icon-ic_round-arrow-drop-down:before {
  content: "\e90e";
  color: #75777c;
}

@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Digi";
  src: local("Digi"), url("fonts/DS-DIGIB.TTF") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
