.time-driven-action-table {
  padding: 30px 12px 12px 12px;
  //margin-bottom: 15px;

  thead,
  th {
    // background-color: #11223673;
    background-color: #fff;
    color: #000;
    width: 10% !important;
    padding: 10px;
    &:last-child {
      width: 20% !important;
    }
  }

  .view-details-from-table {
    cursor: pointer;
    margin-left: 5px;
    // text-decoration: underline;
    border-bottom: 2px solid #446EB8;
    border-radius: 2px;
    display: table-cell;
  }

  .pagination-container {
    margin-bottom: 30px;
  }
}

.maintenance-log-graph-container {
  width: 80%;
  height: 80%;
}

.log-graph {
  .modal-close {
    position: relative;
    left: 10px;
    top: -10px;
  }
  .modal-body {
    position: relative;
    right: 7px;
  }
}
