.highcharts-figure {
    width: 30%;
    margin: 0.5rem;
    border-radius: 6px;
}

.figure-large-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 80%;
    height: 100%;

    .modal-close {
        position: absolute;
        top: 20px;
        right: 5px;
        z-index: 5;
    }

    .highcharts-figure-large {
        margin: 1em 0em;
        height: 100%;
    }

    .figurre-content {
        height: 100%;
    }
}

rect.highcharts-background {
    rx:20;
    ry:20
}

.highcharts-border-radius .highcharts-series rect:first-of-type {
    border-top-left-radius: 10px;
}

.highcharts-border-radius .highcharts-series rect:last-of-type {
    border-top-right-radius: 10px;
}