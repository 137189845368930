.vechile-information {
   margin-top: 20px;
  // padding-top: 16px;
  .left-container {
    padding-left: 10px;
    .vehicle-info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        width: 40%;
      }
    }
  }

  .right-container {
    .img-container {
      margin-top: 20px;
      // background: #fcfdff;
      max-width: 390px;
      // box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
      border-radius: 20px;
      text-align: center;
    }

    .text-container {
      padding-top: 30px;
      max-width: 390px;

      .italic {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .vehicle-description {
    background: #FFF6EB;
    border-radius: 10px;
    width: 200%;
    height: 40px;
    //font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 118.18%;
    color: #353536;
    padding: 5px;
    margin-bottom: 30%;
    margin-top: 4%;
  }
}

@media screen and (min-width: 1100px) {
  .vehicle-info-container {
    p {
      font-size: 14px;
    }
  }

  .vechile-information .right-container .text-container .italic {
    font-size: 14px;
    line-height: 20px;
  }

  .vechile-information .right-container .img-container {
    max-width: 500px;
  }
}

@media screen and (min-width: 1400px) {
  .vehicle-info-container {
    p {
      font-size: 16px;
    }
  }

  .vechile-information .right-container .text-container .italic {
    font-size: 16px;
    line-height: 26px;
  }

  .vechile-information .right-container .img-container {
    max-width: 430px;
  }
}

@media screen and (min-width: 1700px) {
  .vehicle-info-container {
    p {
      font-size: 16px;
    }
  }

  .vechile-information .right-container .text-container .italic {
    font-size: 16px;
    line-height: 30px;
  }

  .vechile-information .right-container .img-container {
    max-width: 500px;
  }
}