.component-system-history-maintenance-history-table {
    padding: 30px 60px 90px;
    margin-top: 10px;


    thead,
    th {
        background-color: #FFFFFF;
        padding: 10px;

        &:nth-child(1) {
            width: 20% !important;
            border-radius: 20px 0px 0px 0px !important;
        }

        &:nth-child(2) {
            width: 80% !important;
            border-radius: 0px 20px 0px 0px !important;
        }
    }

    tr {
        box-shadow: none !important;

        &:last-child {
            & td {
                &:last-child {
                    border-radius: 0px 0px 20px 0px !important;
                }

                &:nth-child(1) {
                    border-radius: 0px 0px 0px 20px !important;
                }
            }
        }
    }

    .view-details-from-table {
        cursor: pointer;
        margin-left: 5px;
        text-decoration: underline;
    }

    .pagination-container {
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1100px) {
        .component-system-history-maintenance-history-table {
            padding: 30px 30px 90px;
        }
    }

    @media screen and (min-width: 1700px) {
        .component-system-history-maintenance-history-table {
            padding: 30px 60px 90px;
        }
    }
}