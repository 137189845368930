.picRepbar-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0;
}

.picRepbar_footer {
  .picRepbar_footer--right {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
    flex-direction: column;
    padding: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #353536;
    .picRepbar_footer--row {
      display: flex;
      flex-direction: row;
    }
    span {
      display: block;
      margin: 1px 3px 0px 0px;
      width: 120px;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      padding-top: 10px;
    }
    span:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      border: 0.5px solid #000000;
    }
    .one:before {
      background: #f3f2f5;
    }
    .two:before {
      background: #0574a6;
    }
    .three:before {
      background: #09a1a1;
    }
    .four:before {
      background: #dd359a;
    }
  }
}

.bar__container {
  justify-self: center;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 100px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #353536;
  height: 100%;

  .bar__wrapper--footer {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .dashed_line {
    //border-bottom: 1px solid #000000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 200px;
  }
  .bar__wrapper {
    border-bottom: 1px solid #000000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 200px;
    position: relative;
    //margin:" 0px 20px 0px 20px ";
    .bar {
      width: 20%;
      position: relative;
      .bar-track {
        width: 100%;
        background: #f3f2f5;
        border: 1px solid #000000;
        position: relative;
        border-bottom: none;

        .bar-fill {
          width: 100%;
          color: #000000;
          text-align: center;
          position: absolute;
          z-index: 0;
          bottom: 0;
          background-color: #000000;
          // border-top: 1px solid #000000;
          max-height: 100%;

          span {
            display: flex;
            justify-content: center;
            position: absolute;
            z-index: 1;
            top: -10px;
            width: inherit;
            left: 0;
            transform: translateY(-50%);
            font-size: 9px;
            padding-top: 5px;
          }
        }
        span {
          display: flex;
          justify-content: center;
          position: absolute;
          z-index: 1;
          top: -10px;
          width: inherit;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.ideal_behavior_container {
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 100%;
}
.idealBehaviour {
  font-size: 12px !important;
  padding: 10px;
}
