.tabs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 60px;
}

.poc-graph {
  margin-right: 30px;
  border: 1px solid #446eb8;
  background-color: transparent;
  color:#446eb8;
  align-content: center;
  text-align: center;
  border-radius: 4px;
  width: auto;

}
.poc-graph:hover {
  color: #164db4;
  border-color: #164db4;
}

.poc-status-button {
  display: flex;
  justify-content: flex-end;
}

.poc-highLight-button {
  background-color: #446eb8;
  color: white;
  border-color: #446eb8;
}
.poc-highLight-button:hover {
  background-color:#164db4;
  color: white;
}

.tabs {
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 6px;
  align-content: center;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(100, 116, 139, 0.12);
}

@media screen and (min-width: 1100px) {
  .tabs-container {
    padding: 0px 30px;
  }
}

@media screen and (min-width: 1700px) {
  .tabs-container {
    padding: 0px 60px;
  }
}