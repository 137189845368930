.prescribed_actions {
  padding: 30px;
  .sensor {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  .actions_table {
    table {
      th {
        background-color: #ffffff;
        padding: 20px;

        &:nth-child(1) {
          width: 35% !important;
          border-radius: 20px 0px 0px 0px !important;
        }

        &:nth-child(2) {
          width: 35% !important;
        }

        &:nth-child(3) {
          width: 35% !important;
          border-radius: 0px 20px 0px 0px !important;
        }
      }

      tr {
        box-shadow: none !important;
        &:last-child {
          & td {
            &:last-child {
              border-radius: 0px 0px 20px 0px !important;
            }

            &:nth-child(1) {
              border-radius: 0px 0px 0px 20px !important;
            }
          }
        }
      }
    }
  }
}
