.rca__container {
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  .rca--content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 520px;
    align-content: flex-start;
    .key-value-holder {
      width: 48%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .key-name {
        width: 45%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        span {
          color: #f23732;
        }
      }
      .value-details {
        width: 50%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
  .rca--footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin:5px 30px 40px 0px;
    button:first-of-type {
      margin-right: 10px;
    }
    button {
      width: auto;
    }
  }
  .rca--title {
    p {
       text-align: center;
      margin-left: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
