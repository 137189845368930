.rem-test-hdr {
  padding: 0px 30px 0px 30px;
  flex-direction: column;
}

.rem-test-graph {
  height: 360px;

  .highcharts-figure {
    width: 100%;
  }
}

.rem-test-diag-text {
  margin: 30px;
  height: 280px;
}

.rem-test-diag-text-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  margin: 5px 0px 5px 0px;
}

.rem-test-diag-text-description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #353536;
}

.rem-test-card {
  width: 45%;
  display: inline-block;
  padding: 15px;
}

.full-width {
  &.vehicle-health-check {
    background-color: #082336;
    width: 105%;
    position: relative;
    right: 10px;
    height: 48px;
    .tab-outlet {
      width: 2px;
    }
  }
}

.rem-test-graph-text {
  position: relative;
  bottom: 10px;
  left: 8px;
  font-size: 14px;
  align-items: flex-end;
  padding: 10px 15px;
  background: #fffdfd;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
  font-size: 14px;

  button {
    margin-top: 10px;
    width: 160px;
    display: flex;
    color: #446eb8;
    position: relative;
    right: 30px;
    bottom: 10px;
    text-decoration: underline;
  }
}

.rem-test-diagrams {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: baseline;

  > div {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .abs-system-daigram-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .rem-test-graph-abs {
    width: 100%;
  }
}

.rem-test-abs-diagram {
  .rem-test-abs-diagram-image {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #cecece;
  }
}

.rem-test-hdr {
  .remotet {
    margin-top: 20px;
    font-size: 14px;
    input[type="checkbox"] + span {
      top: 0px;
    }

    input[type="checkbox"]:disabled + span {
      border: 1.5px solid #a2a2a2;
    }

    div {
      padding: 10px;
      width: 152px;
      margin-right: 20px;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 30px;

      label {
        gap: 30px;
      }
    }

    .filter-label-title {
      padding: 0px;
      border: none;
      box-shadow: none;
      width: 0px;
      margin-right: 0px;
      background: #ffffff;
      border-radius: 0px;
    }
  }
}

.rem-img-text {
  font-weight: 600;
  font-size: 13px;
  text-align: center;
}

.rem-test-graph.rem-test-graph-abs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .nav-icons {
    &.disable-left {
      pointer-events: none;
      opacity: 0.7;
    }

    &.disable-right {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .large-charts {
    width: 80%;
    position: relative;

    .modal-close {
      position: absolute;
      top: 10px;
      right: -5px;
      z-index: 3;
    }
  }
}

.rem-test-diag-text {
  margin: 30px;
  height: 280px;
}

.rem-test-diag-text-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  margin: 5px 0px 5px 0px;
}

.rem-test-diag-text-description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #353536;
}

.full-width {
  &.vehicle-health-check {
    background-color: #082336;
    width: 105%;
    position: relative;
    right: 10px;
    height: 48px;
    .tab-outlet {
      width: 500px;
      margin-bottom: 10px;
    }
  }
}
.rem-test-abs-diagram-container {
  padding-bottom: 50px;
}

@media screen and (min-width: 1400px) {
  .rem-test-graph-text {
    font-size: 16px;
  }
}

.page-wrapper-rad {
  border: 1px solid;
  background: #ffffff;
  border-radius: 10px;
}

.btn-btn-primary {
  color: #ffffff;
  background-color: #446eb8;
}

.btn-btn-disabled {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    #446eb8;
  color: #fff;
}
.rem-test-diag-text ul li {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #353536;
}
