.filter-wrapper {
  border: 1px solid #c4c4c4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.filter-options-container {
  position: relative;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f9f9fa;
  }
}

.filter-wrapper {
  position: absolute;
  top: 0;
  left: -11rem;
  right: 0;
  z-index: 9999;
  background: #000000;
  max-height: 200px;
  overflow-y: scroll;
  color: white;
  border-radius: 15px;
}

.filter-label {
  align-items: end !important;
  span {
    min-width: 13px !important;
  }
}

.filter-icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.filter-container {
  cursor: pointer;
  padding: 10px 15px;
  font-size: 18px;
  background-color: #f9f9fa;
  border: 1px solid #f9f9fa;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  display: flex;
  align-items: center;
}

.page-filter-wrapper {
  align-self: flex-end;
}
.filter-wrapper {
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 12px;
    height: 12px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: black left top no-repeat;
    border: 1px solid #ffffff;
    cursor: pointer;
  }
  input[type="checkbox"]:checked + span::before {
    content: url(../../assets/checkbox-tick-white.svg);
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="checkbox"] + span {
    margin-right: 4px;
  }
}

.saved-filter-container {
  input[type="checkbox"]:checked + span::before {
    content: url(../../assets/checkbox-tick.svg);
    height: 12px;
    width: 12px;
  }
}

@media screen and (min-width: 1100px) {
  .filter-container {
    font-size: 14px;
    border-radius: 10px;
  }

  .filter-wrapper {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  .filter-container {
    font-size: 16px;
  }

  .filter-wrapper {
    font-size: 16px;
  }
}
