@import "../../../scss/colors";

.select-container {
  max-width: 200px;
  min-width: 150px;
  margin-right: 20px;
}

.heading-container {
  display: flex;
  background: #ffffff;
  border: 1px solid #f9f9fa;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  width: 100%;
  padding: 8px;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  & .heading {
    font-size: $font14;
    color: #000000;
    padding-left: 8px;
  }

  & .pre-icon-img {
    width: 18px;
    height: 18px;
  }
}

.select-options-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.select-option {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #ffffff;
  z-index: 100;
}

.option-value {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #c4c4c4;

  &:hover {
    .tick-icon {
      visibility: visible;
    }
  }

  font-size: 14px;
}

.tick-icon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  visibility: hidden;

  &.selected {
    visibility: visible;
  }
}

.heading-icon-img {
  padding-right: 2px;
}

.heading-label {
  margin-left: 8px;
  height: 15px;
  left: 383px;
  top: 99px;
  font-weight: 400;
  font-size: 10px;
  line-height: 23px;
  font-style: italic;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
}

.top-10-input-box {
  border: 1px solid black;
  height: 30px;
  width: 80%;
}

.tab {
  width: 110%;
  position: relative;
  right: 30px;
}

.top-10-dtc {
  margin-right: 0px;
}
