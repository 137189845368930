.sec-tabs-container {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  // padding: 0px 60px;
  position: relative;
  // gap: 28px
}

.sec-tabs {
  padding: 10px 30px;
  text-decoration: none;
  //  border-radius: 3px;
  width: 250px;
  align-content: center;
  text-align: center;
  border: none;
  outline: none;
}

a.btn-primary.sec-tabs:hover {
  background-color: transparent;
  color: #000000;
}

a.btn-secondary.sec-tabs:hover {
  background-color: transparent;
  color: #000000;
}

.btn-primary-sec-tabs {
  padding: 4px;
  text-decoration: none;
  border-bottom: 3px solid orange;
  margin: 10px;
  color: #000000;
  border-bottom: 4px solid #e58817;
  font-size: 14px;
  font-weight: 600;
}

.btn-secondary-sec-tabs {
  padding: 4px;
  text-decoration: none;
  margin: 10px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}
