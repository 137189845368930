@import "../../scss/colors";
@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,500;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
.login {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;

  .login__Form {
    flex: 1;
    font-family: "Open Sans";
    width: 100%;
    background-color: #ffffff;
    padding: 25px 40px;
    overflow: auto;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;

    .login--logo {
      max-width: 261px;
      max-height: 52px;
      margin-bottom: 40px;
    }

    .login__rightside__container {
      width: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      color: #363636;

      .login__Form--title {
        p {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 29px;
          color: #494949;
          padding-bottom: 20px;
        }
      }

      .login__Form--footer {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 220px;
        margin: 10px 0px;

        span:first-child {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5;
          margin: 5px 0px;
        }
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 1.5;
          color: $primary;

          a {
            text-decoration: none;
            color: #0147ab;
            font-size: 16px;
            &:hover {
              color: #085fda;
            }
          }
        }
      }
      .login__Form--forgettext {
        display: flex;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 1.5;
          color: #0147ab;
          cursor: pointer;

          &:hover {
            color: #085fda;
          }
        }
      }

      .login__Form--btn {
        margin: 10px 35px;

        .btn-primary-blue {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 10px 20px;
          border-radius: 30px;
          background: #0147ab;
          width: 160px;
          cursor: pointer;
          &:hover {
            background-color: #1458b8;
          }
        }
      }

      .login__Form--inputs {
        width: 75%;

        input {
          border-radius: 35px;
          width: 416px;
          padding: 10px 30px;
        }
      }
    }
  }
}
.forgotpwd__Form {
  width: 100%;
  padding: 20px;
  &--inputs {
    width: 100%;
    input {
      width: 100%;
      border-radius: 5px;
      padding: 5px !important;
    }
    button {
      border-radius: 5px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5;
      padding: 5px 30px;
      background: #0147ab;

      &:hover {
        background-color: #1458b8;
      }
    }
  }
  .forgotpwd__Form--title {
    p {
      margin: 0px 0px 10px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 150%;
      color: rgba(68, 110, 184, 1);
    }
  }
  .forgotpwd__Form--footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: end;
    margin: 10px 0px;

    .footer-items {
      display: flex;
      flex-direction: column;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 13px !important;
      line-height: 1.5;
      text-decoration-line: underline;
      color: rgba(68, 110, 184, 1);
      span {
        cursor: pointer;
      }
      span:first-child {
        margin: 5px 0px;
      }
    }
  }
}
.rsetpwd__Form {
  width: 100%;
  padding: 10px;
  &--inputs {
    width: 100%;
    input {
      width: 100%;
      border-radius: 5px;
      padding: 5px !important;
    }
    button {
      border-radius: 5px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5;
      padding: 5px 30px;
    }
  }
  .rsetpwd__Form--title {
    margin-bottom: 10px;
    p {
      margin: 0px 0px 10px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.5;
      color: rgba(68, 110, 184, 1);
    }
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #747577;
    }
  }
  .rsetpwd__Form--btn {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    .btn-primary-blue {
      width: 100px;
    }
  }
}
.Form--error {
  color: $error;
  display: flex;
  justify-content: center;
}
.Form--success {
  color: green;
  display: flex;
  justify-content: center;
}
::-ms-reveal {
  display: none;
}

input[type="text"]::-webkit-contacts-auto-fill-button,
input[type="text"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.limitexceed--footer {
  p {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    text-decoration-line: underline;
    color: #0147ab;
    span {
      cursor: pointer;
    }
  }
}
.eye-icon {
  position: relative;
  bottom: 30px;
  left: 370px;
  cursor: pointer;
  height: 0px;
}
.login-form-footer {
  display: flex;
  align-items: center;
}
.signup-footer {
  display: flex;
  align-items: center;
  gap: 16px;
}
.input__container-error-msg {
  font-size: 12px;
  color: red;
  margin-bottom: 5px;
  padding-left: 15px;
}

.rsetpwd__Form--inputs {
  .eye-icon {
    left: 506px !important;
    bottom: 27px;
  }
}

.setpwd-close {
  position: relative;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: end;
  cursor: pointer;
  img {
    width: 25px;
    height: 25px;
  }
}

.setpwd__Form--inputs {
  input {
    border-radius: 20px !important;
    padding: 20px !important;
  }
  .input__field {
    input {
      font-size: 14px;
      padding: 10px 40px 10px 15px !important;
    }
  }
  .eye-icon {
    left: 490px !important;
  }
}

@media screen and (max-width: 400px) {
  .login__Form {
    padding: 0px !important;
  }
  .login__Form--inputs {
    input {
      width: 245px !important;
    }
  }
  .login--logo {
    margin-bottom: 0px !important;
  }
  .login__Form--title {
    p {
      padding-bottom: 0px !important;
    }
  }
  .eye-icon {
    left: 211px !important;
  }
  .login__Form--btn {
    img {
      width: 12px;
      height: 15px;
    }
    margin: 0px !important;
    .btn-primary-blue {
      font-size: 10px;
      width: 110px !important;
      gap: 4px !important;
    }
  }
  .login-form-footer {
    display: flex;
    gap: 15px;
  }
  .login__rightside__container {
    align-items: center;
    padding: 0px !important;
  }
  .Form--error {
    font-size: 11px;
  }
  .input__container-error-msg {
    font-size: 10px;
    color: red;
    margin-bottom: 5px;
    padding-left: 15px;
  }
  .login__Form--footer {
    width: 140px !important;
    span {
      font-size: 11px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  .signup__rightside__container {
    padding: 0px !important;
    align-items: center;
  }
  .signup .signup__Form {
    padding: 10px 0px !important;
  }
  .signup__Form--title {
    p {
      font-size: 16px !important;
      font-weight: 400;
      margin: 4px;
    }
  }
  .signup__Form--inputs {
    input {
      width: 260px !important;
      padding: 6px 30px !important;
    }
    textarea {
      width: 260px !important;
    }
    .textareabox {
      height: 90px;
      width: 260px;
    }
    textarea[required] + label {
      font-size: 10px !important;
    }
    span {
      font-size: 9px !important;
    }
  }
  .signup__Form--phnumber {
    width: 260px;
    height: 45px;
    input {
      width: 260px !important;
      padding: 10px !important;
    }
  }
  .signup-footer {
    display: flex;
    gap: 15px;
  }
  .signup__Form--footer {
    span {
      font-size: 12px !important;
      margin: 2px !important;
    }
  }
  .signup__Form--btn {
    img {
      width: 12px;
      height: 15px;
    }
    margin: 0px !important;
    .btn-primary-blue {
      font-size: 10px;
      width: 110px !important;
      gap: 4px !important;
    }
  }
  .signup__Form--btn {
    .btn-primary-blue {
      padding: 6px !important;
    }
  }
  .setpwd__Form--inputs {
    .eye-icon {
      left: 164px !important;
    }
  }
  .modal-container.medium {
    width: 270px !important;
  }
  .forgotpwd__Form--title {
    p {
      font-size: 14px !important;
    }
  }
  .forgotpwd__Form--inputs {
    input {
      font-size: 12px !important;
    }
  }
  .forgotpwd__Form--inputs {
    button {
      font-size: 12px !important;
    }
  }
  .footer-items {
    span:first-child {
      font-size: 10px;
      margin: 0px !important;
    }
  }

  .rsetpwd__Form--title {
    p {
      font-size: 14px !important;
    }
  }
  .rsetpwd__Form--title {
    span {
      font-size: 12px !important;
    }
  }
  .rsetpwd__Form--inputs {
    .eye-icon {
      left: 189px !important;
      bottom: 27px;
    }
  }
  .rsetpwd__Form--inputs {
    .input__container {
      height: 50px;
      font-size: 12px !important;
    }
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
  .login__rightside__container {
    padding: 40px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 500px) {
  .login__Form {
    padding: 25px !important;
  }
  .login__Form--inputs {
    input {
      width: 300px !important;
    }
  }
  .login--logo {
    margin-bottom: 0px !important;
  }
  .login__Form--title {
    p {
      padding-bottom: 0px !important;
    }
  }
  .eye-icon {
    left: 260px !important;
  }
  .login__Form--btn {
    &.btn-primary-blue {
      width: 100px;
    }
  }
  .login-form-footer {
    width: 389px;
    margin-left: 55px;
  }
  .login__rightside__container {
    align-items: center;
    padding: 0px !important;
  }
  .login__Form--footer {
    span:first-child {
      font-size: 12px !important;
    }
  }

  .signup .signup__Form {
    padding: 10px 0px !important;
  }
  .signup__Form--title {
    p {
      font-size: 16px !important;
      font-weight: 400;
      margin: 4px;
    }
  }
  .signup__Form--inputs {
    input {
      width: 360px !important;
      padding: 6px 30px !important;
    }
    textarea {
      width: 360px !important;
    }
    .textareabox {
      height: 90px;
      width: 360px;
    }
    textarea[required] + label {
      font-size: 12px !important;
    }
  }
  .signup__Form--phnumber {
    width: 360px;
    input {
      width: 360px !important;
      padding: 10px !important;
    }
  }
  .signup__Form--footer {
    span {
      font-size: 13px !important;
    }
  }
  .signup__Form--btn {
    .btn-primary-blue {
      width: 120px !important;
      padding: 6px !important;
    }
  }

  .modal-container.medium {
    width: 370px !important;
  }
  .forgotpwd__Form--title {
    p {
      font-size: 16px !important;
    }
  }
  .forgotpwd__Form--inputs {
    input {
      font-size: 14px !important;
    }
  }
  .forgotpwd__Form--inputs {
    button {
      font-size: 14px !important;
    }
  }
  .footer-items {
    span:first-child {
      font-size: 12px;
      margin: 2px !important;
    }
  }

  .rsetpwd__Form--title {
    p {
      font-size: 16px !important;
    }
  }
  .rsetpwd__Form--inputs {
    .eye-icon {
      left: 280px !important;
      bottom: 27px;
    }
  }
  .rsetpwd__Form--inputs {
    .input__container {
      height: 50px;
      font-size: 12px !important;
    }
  }
  .setpwd__Form--inputs {
    .eye-icon {
      left: 260px !important;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .login__Form {
    padding: 50px !important;
  }
  .login__Form--inputs {
    input {
      width: 300px !important;
    }
  }
  .login--logo {
    margin-bottom: 0px !important;
  }
  .login__Form--title {
    p {
      padding-bottom: 0px !important;
    }
  }
  .eye-icon {
    left: 260px !important;
  }
  .login__Form--btn {
    &.btn-primary-blue {
      width: 100px;
    }
  }
  .login-form-footer {
    width: 389px;
    margin-left: 55px;
  }
  .login__rightside__container {
    align-items: center;
    padding: 0px !important;
  }
  .login__Form--footer {
    span:first-child {
      font-size: 12px !important;
    }
  }

  .signup .signup__Form {
    padding: 10px 0px !important;
  }
  .signup__Form--title {
    p {
      font-size: 16px !important;
      font-weight: 400;
      margin: 4px;
    }
  }
  .signup__Form--inputs {
    input {
      width: 283px !important;
      padding: 6px 30px !important;
    }
    textarea {
      width: 283px !important;
    }
    .textareabox {
      height: 90px;
      width: 283px;
    }
    textarea[required] + label {
      font-size: 11px !important;
    }
  }
  .signup__Form--phnumber {
    width: 283px;
    input {
      width: 283px !important;
      padding: 10px !important;
    }
  }
  .signup__Form--btn {
    .btn-primary-blue {
      width: 120px !important;
      padding: 6px !important;
    }
  }
  .signup__Form--footer {
    span {
      font-size: 12px !important;
    }
  }

  .rsetpwd__Form--inputs {
    .eye-icon {
      left: 506px !important;
      bottom: 27px;
    }
  }
  .rsetpwd__Form--inputs {
    .input__container {
      height: 50px;
    }
  }

  .setpwd__Form--inputs {
    .eye-icon {
      left: 500px !important;
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1010px) {
  .login__Form {
    padding: 50px !important;
  }
  .login__Form--inputs {
    input {
      width: 350px !important;
    }
  }
  .login--logo {
    margin-bottom: 0px !important;
  }
  .login__Form--title {
    p {
      padding-bottom: 0px !important;
    }
  }
  .eye-icon {
    left: 310px !important;
  }
  .login__Form--btn {
    &.btn-primary-blue {
      width: 100px;
    }
  }
  .login-form-footer {
    width: 389px;
    margin-left: 55px;
  }
  .login__rightside__container {
    align-items: center;
    padding: 0px !important;
  }
  .login__Form--footer {
    span:first-child {
      font-size: 12px !important  ;
    }
  }

  .signup .signup__Form {
    padding: 10px 0px !important;
  }
  .signup__Form--title {
    p {
      font-size: 16px !important;
      font-weight: 400;
    }
  }
  .signup__Form--inputs {
    input {
      width: 225px !important;
    }
    textarea {
      width: 225px !important;
    }
    .textareabox {
      height: 90px;
      width: 230px;
    }
    textarea[required] + label {
      font-size: 10px !important;
    }
  }
  .signup__Form--phnumber {
    width: 230px;
    input {
      width: 225px !important;
      padding: 10px !important;
    }
  }
  .signup-footer {
    display: contents !important;
  }
}
