.vehicle-health-check {
  .legend-container {
    display: flex;
    justify-content: end;
    margin: 10px 0px 0px 0px;
    span {
      font-size: 14px;
    }
  }
  .health-check-checkbox {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    .filter-label {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center !important;
    }
    span {
      top: 1px !important;
    }
    .filter-label-title {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .checkbox-color {
    span {
      background-color: #426ebc;
      border-color: #426ebc;
      color: white;
    }
  }

  .checkbox-Bordercolor {
    span {
      border-color: #426ebc;
      // background-color: white;
    }
  }

  .highcharts-figure {
    width: 100%;
  }
}

.blue-checkbox {
  background-color: blue;
}

.vp-health-filter {
  .filter-label {
    align-items: start !important;
    input[type="checkbox"] + span {
      top: 4px !important;
    }
  }
}
