.graph-info-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
  position: relative;
  top: 22px;
  z-index: 1;
  height: 0;
  right: 40px;

  .graph-inner {
    display: inline-flex;
    justify-content: flex-end;
    //    background: #E3F5E3;
    padding: 0px 8px;
    padding-left: 20px;
    margin-bottom: 10px;

    .inner {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-right: 10px;

      .box {
        height: 12px;
        width: 12px;
        // background-color: green;
      }
    }
  }
}
