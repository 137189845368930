.tabs-container.operational-analytics {
  justify-content: flex-start;
  .tabs {
    margin-right: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.tab-wrap {
  padding-top: 0px !important;
}