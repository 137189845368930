.graph-name {
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #353536;
}

.rul-text {
  display: flex;
  justify-content: end;
  position: relative;
  top: 50px;
  z-index: 99;
  right: 30px;
  color: rgb(56, 110, 214);
  font-size: 10px;
}

.chart-details-wrapper {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  // margin-bottom: 15px;
  align-items: center;
  gap: 10px;
  height: 45px;

  .details-wrapper {
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-right: 5px;
    margin-top: 8px;
    // background-color: #e3f5e3;
    justify-content: space-between;

    .chart-details {
      font-size: 10px;
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .value {
        margin-right: 5px;

        &.green {
          color: #a9d18e;
        }

        &.yellow {
          color: #ffc000;
        }

        &.red {
          color: #f23732;
        }

        &.pink {
          color: #dd359a;
        }
      }
    }
  }

  .green-legend,
  .yellow-legend,
  .red-legend,
  .pink-legend {
    text-align: left;
    margin-top: 5px;
    margin-right: 5px;
  }

  .pink-legend {
    .color-box.pink {
      background-color: #dd359a;
    }

    .color-box.red {
      background-color: #f23732;
    }
  }
}

.axis-legend {
  text-align: left;
}

.x-axis-legend {
  text-align: right;
}

.y-axis-legend {
  text-align: right;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 180px;
  white-space: break-spaces;
}

.y-axis-arrow {
  display: block;
  width: 170px;
  height: 10px;
  background: url("./../../../assets/arrow-yaxis.svg") 100% 100% no-repeat;
}

.tooltip-vehicle-health {
  display: flex;
  background-color: #000;
  color: #e9e9e9;
  font-size: 12px;
  font-weight: 200;

  .flex-column {
    display: flex;
    flex-direction: column;
    border: 0.5px solid #bcbcbc;
  }
  .flex-column-border {
    border-bottom: 0.5px solid #cecece;
    border-left: none;
  }
  .second-column {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
