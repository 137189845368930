@import "../../scss/colors";

.footer {
  // padding: 10px 30px;
  // background-color: $primary;
  color: #bdbdbd;
  // font-size: $font12;
  // z-index: 10;
  // width: 100%;
  // position: fixed;
  // bottom: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767.5px) {
  .footer {
    padding: 5px 10px;
  }
}
