.admin_dashboard {
  padding: 30px;

  .header_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5%;
    text-align: center;

    .admin_page_card {
      display: flex;
      // gap: 20px;
      height: 35px;
      align-items: center;
      // justify-content: space-around;
      // padding: 0px 20px 0px 20px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: #f7f8fa;

      .icon {
        margin: 10px;
      }

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }

      .count {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        margin: 5px;
      }
    }
  }

  .search_input {
    margin: 20px 0px 10px 0px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 5fr;

    .select-container {
      min-width: 164px;
    }

    .dropdown_content {
      .heading-container {
        background-color: #f9f9fa;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        .arrow-icon {
          margin-right: 6px;
        }
      }
    }

    .search_textfield {
      position: relative;
      display: grid;
      grid-template-columns: 4fr 1fr;
      column-gap: 10px;

      .search_field {
        width: 50%;
        border: 1px solid #e2edff;
        border-radius: 5px;
        padding: 10px 30px;
        height: 46px;

        &:focus {
          border-color: #e2edff;
          border-width: 2px;
          transform: 0.5s all ease;
        }

        &:focus-visible {
          outline: none;
        }
      }

      .search_image {
        position: absolute;
        top: 9px;
        left: 236px;
        cursor: pointer;
        padding: 7px;

        &:hover {
          transform: scale(1.1);
          transition: 0.5s all ease;
        }
      }
    }
  }

  .react_date {
    input {
      // border: 1px solid #cfcfd0;
      padding: 10px 20px;

      // &:focus {
      //   outline: none;
      //  // border: 1px solid #cfcfd0;
      // }
    }
  }

  .Admin-dashboard-datepicker {
    width: 150px;
    border: none;
  }

  .Admin-dashboard-datepicker:hover {
    background-color: black;
  }

  .Admin-dashboard-datepicker-primary {
    width: 105px;
    border: none;
  }

  .users_table {
    table {
      padding: 0px;
      margin-top: 20px;
      box-shadow: 0px 1px 5px rgba(100, 116, 139, 0.12);
      border-radius: 8px;

      td {
        border-left: none;
      }

      thead {
        background-color: #ffffff;

        tr {
          th {
            background-color: #ffffff;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            border: 2px solid #e2edff;

            &:nth-child(1) {
              border-right: none;
              border-bottom: none;
            }

            &:nth-child(2) {
              border-right: none;
              border-bottom: none;
              border-left: none;
            }

            &:nth-child(3) {
              border-right: none;
              border-bottom: none;
              border-left: none;
            }

            &:nth-child(4) {
              border-right: none;
              border-bottom: none;
              border-left: none;
            }

            &:nth-child(5) {
              border-left: none;
              border-bottom: none;
              border-radius: 0px 20px 0px 0px !important;
            }
          }

          .name {
            width: 18%;
          }

          .mail {
            width: 28%;
          }

          .date {
            width: 18%;
          }

          .status {
            width: 18%;
          }

          .actions {
            width: 18%;
          }
        }
      }

      tbody {
        tr {
          &:last-child {
            td {
              &:nth-child(1) {
                border-bottom: 2px solid #e2edff;
                padding: 15px;
              }

              &:nth-child(2) {
                border-bottom: 2px solid #e2edff;
              }

              &:nth-child(3) {
                border-bottom: 2px solid #e2edff;
              }

              &:nth-child(4) {
                border-bottom: 2px solid #e2edff;
              }

              &:nth-child(5) {
                border-bottom: 2px solid #e2edff;
              }
            }
          }

          td {
            border: 2px solid #e2edff;
            height: 50px;
            padding: 0px 10px;

            &:nth-child(1) {
              border-right: none;
              border-bottom: none;
            }

            &:nth-child(2) {
              border-right: none;
              border-bottom: none;
              border-left: none;
            }

            &:nth-child(3) {
              border-right: none;
              border-bottom: none;
              border-left: none;
            }

            &:nth-child(4) {
              border-right: none;
              border-bottom: none;
              border-left: none;
            }

            &:nth-child(5) {
              border-left: none;
              border-bottom: none;
            }

            .action_container {
              display: flex;
              column-gap: 12px;

              div {
                padding: 10px 14px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                  transform: scale(1.05);
                  transition: 0.5s all ease;
                }
              }

              .confirm_user {
                background-color: #fff;
              }

              .edit_user {
                background-color: #fff;
              }

              .delete_user {
                background-color: #fff;
              }

              .view_user {
                background-color: #e2edff;
              }

              .space_div {
                padding: 10px 20px;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }

  .confirm_user_modal {

    // margin-top: 30px;
    .modal-container {
      min-width: 600px;
      border-radius: 20px;

      .modal-close {
        img {
          top: -6px;
          right: -5px;
        }
      }
    }

    .user_details {
      .user_keys {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p:nth-child(1) {
          width: 35%;
        }

        p:nth-child(2) {
          width: 55%;
        }
      }
    }

    .button_container {
      display: flex;
      justify-content: flex-end;

      .cancel_button {
        width: auto;
        margin-right: 20px;
      }

      .approve_button {
        width: auto;
      }
    }
  }

  .edit_user_modal {
    .modal-container {
      min-width: 450px;
      width: auto;
      border-radius: 20px;

      .modal-close {
        img {
          top: -6px;
          right: -5px;
        }
      }

      .authorisation_details {
        .radio_group {
          margin-top: 20px;

          input {
            margin-left: 30px;
            margin-right: 8px;
          }

          input:nth-child(1) {
            margin-left: 0px;
          }
        }

        .button_container {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;

          button {
            width: auto;
          }

          .cancel_button {
            font-size: 14px;
            padding: 9px 20px;
            margin-right: 10px;
          }

          .approve_button {
            font-size: 14px;
            padding: 9px 20px;
          }
        }
      }
    }
  }

  .delete_user_modal {
    .modal-container {
      min-width: 450px;
      width: auto;
      border-radius: 20px;

      .modal-close {
        img {
          top: -6px;
          right: -5px;
        }
      }
    }

    .delete_details {
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
      }

      .delete_button_container {
        display: flex;
        justify-content: flex-end;

        button {
          width: auto;
        }

        .cancel_button {
          font-size: 14px;
          padding: 9px 20px;
          margin-right: 10px;
        }

        .approve_button {
          font-size: 14px;
          padding: 9px 20px;
        }
      }
    }
  }

  .remark_modal {
    .remark_heading {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin: 0px;
      margin-bottom: 10px;
    }

    .remarks_text {
      width: 100%;
      border: 1px solid #c9ced7;

      &:focus {
        border: 1px solid #c9ced7;
        outline: none;
      }
    }

    .button_container {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;

      button {
        width: auto;
      }

      .cancel_button {
        font-size: 14px;
        padding: 9px 20px;
        margin-right: 10px;
      }

      .approve_button {
        font-size: 14px;
        padding: 9px 20px;
      }
    }
  }
}

.configure-feature {
  text-align: center;
  padding-top: 50px;

  .upload-text {
    margin-left: 20px;
    margin-right: 100px;
  }

  input {
    padding-top: 10px;
  }

  button {
    border: 1px solid black;
    height: 40px;
  }

}

.upload_Error_Msg {
  margin-top: 50px;
  color: red;
  margin-right: 100px;
  padding-bottom: 50px;
}

.clear-all-text {
  display: flex;
  margin-bottom: 30px;
  margin-top: 50px;
}

.upload-file {
  display: flex;
  margin-bottom: 40px;
}