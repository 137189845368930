.maintenance-log-hdr {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 20px;
}

.maintenance-log-search {
  width: 60%;
}
.maintenance-log-filters {
  width: 100%;
  padding: 0 60px;

  display: flex;
  justify-content: end;
  margin-top: 1.2rem;
  .filter-category {
    padding-left: 10px;
  }
}

.maintenance-log-container {
  background-color: #ffffff;
  // padding: 10px 0px 20px 0px;
  //padding:20px;
  .filter-label {
    align-items: start !important;
  }
  .filter-wrapper {
    input[type="checkbox"] + span {
      top: 4px !important;
    }
  }

  .maintainence-modal {
    overflow-y: hidden;
    .maintainence-select {
      width: 90%;
    }
    .modal-header {
      padding-bottom: 16px;
    }
    .modal-close {
      position: relative;
      right: -13px;
      top: -10px;
    }
    .select-container {
      max-width: none;
      margin-right: 10px;
      .select-option {
        height: 180px;
        overflow-y: auto;
      }
    }
    .date-field-disabled {
      input {
        cursor: not-allowed;
      }
    }
    .dtc-desc-disabled {
      input {
        cursor: not-allowed;
      }
    }
    .error-text {
      padding-top: 2px;
      color: #ee1313;
      font-size: 14px;
      position: absolute;
    }
  }
  .maintenance-help-btn {
    margin-right: 20px;
    display: flex;
    justify-content: end;
    .btn-secondary-blue {
      background-color: #fff;
    }
  }
  .maintenance-help-wrapper {
    .maintenance-help {
      background-color: transparent;
      padding: 10px;
      color: #345fab;
      border: 1px solid #345fab;
      margin-right: 20px;
      box-shadow: 0px 1px 5px rgba(100, 116, 139, 0.12);
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      font-size: 16px;
      font-weight: 600;
      .btn-primary {
        font-size: 10px;
        width: auto;
        padding: 5px;
        margin-left: 10px;
      }
    }
  }
  .maintenance-log-table th:nth-last-child(1) {
    border-radius: 0px 20px 0px 0px !important;
  }
  .maintenance-log-table table tr {
    background-color: #fff;
  }
  tr {
    box-shadow: none !important;

    &:last-child {
      & td {
        &:last-child {
          border-radius: 0px 0px 20px 0px !important;
        }

        &:nth-child(1) {
          border-radius: 0px 0px 0px 20px !important;
        }
      }
    }
  }
  .green-container {
    background-color: #b6e5fa;
    padding: 5px;
    // box-shadow: 0px 8px 28px -6px rgb(24 39 75 / 12%),
    //   0px 18px 88px -4px rgb(24 39 75 / 14%);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    // line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 2px;
    margin-right: 5px;
  }
  //   .maintenance-help-wrapper {
  //     display: flex;
  //     justify-content: end;
  // }
  .show-more-btn {
    //border: 1px solid #eaeaea;
    padding: 4px 12px;
    margin: 4px 20px;
    margin-bottom: 20px;
    color: #fff;
  }
}

.add-activity-btn {
  position: fixed;
  right: 50px;
  bottom: 30%;
  width: auto;
  padding: 10px;
  > span {
    float: left;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #fff;
    line-height: 12px;
    margin-right: 10px;
  }
}
table.time-driven-action-table th:last-child {
  border-radius: 0px 20px 0px 0px !important;
}
tr {
  box-shadow: none !important;

  &:last-child {
    & td {
      &:last-child {
        border-radius: 0px 0px 20px 0px !important;
      }

      &:nth-child(1) {
        border-radius: 0px 0px 0px 20px !important;
      }
    }
  }
}
.filter-category {
  font-weight: 600;
  font-size: 16px;
}

.table-buttons-container {
  justify-content: space-between;
  padding: 30px;
  .table-buttons {
    width: auto;
    padding: 5px 15px;
    &.disabled {
      opacity: 0.6;
      cursor: auto;
    }
  }
}
.show-searching-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: space-evenly;
  margin: -2px 0 0 0;
  font-size: 12px;
  align-items: center;
  padding: 20px 60px 0px 60px;
}

@media screen and (min-width: 1100px) {
  .maintenance-log-hdr {
    padding: 20px 0px 20px 20px;
  }
  .maintenance-log-filters {
    padding: 0 30px;
  }
  .maintenance-log-table {
    font-size: 14px;
  }
}

.log-graph .modal-container {
  position: relative;
  left: 18px;
}

@media screen and (min-width: 1300px) {
  .add-activity-btn {
    right: 80px !important;
  }
}

@media screen and (min-width: 1400px) {
  .maintenance-log-table {
    font-size: 16px;
  }
  .add-activity-btn {
    right: 120px !important;
  }
}

@media screen and (min-width: 1700px) {
  .maintenance-log-hdr {
    padding: 20px 0px 20px 20px;
  }
  .maintenance-log-filters {
    padding: 0 60px;
  }
  .maintenance-log-table {
    table {
      padding: 30px 60px;
    }
  }
}
// Hack to disable selectALl PS: do-not use this class elsewhere

.m__logs_filter {
  div:first-child {
    display: none;
  }
}
// .maintenance-log-summary-wrapper {
//   padding: 10px 5px;
// }
