@import "../../../scss/colors";

.multi-select-container {
    max-width: 200px;
    margin-right: 20px;
}

.heading-container {
    display: flex;
    background: #F9F9FA;
    border: 1px solid #F9F9FA;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    width: 100%;
    padding: 8px;
    justify-content: space-between;
    align-items: center;

    & .heading {
        font-size: $font14;
        color: #353536;
        padding-left: 8px;
        padding-right: 5px;
    }

    & .pre-icon-img {
        width: 18px;
        height: 18px;
    }

    & .arrow-icon {
        width: 11px;
        left: 31.32%;
        right: 32.32%;
        top: 38.93%;
        bottom: 25.5%;
        height: 28px;
    }
}

.multi-select-options-container {
    position: relative;
}

.multi-select-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #F9F9FA;
}