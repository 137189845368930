@import "../../scss/colors";
.warranty-management-container {
  margin-top: 10px;
  .diagnostics-container {
    .sec-tabs {
      width: auto;
      box-shadow: none;
    }
    .diagnostics-topdtc-complaints {
      padding: 30px 30px;
      margin-top: 20px;

      .first-container {
        display: flex;
        grid-gap: 80px;
        padding-top: 0px;
        justify-content: center;
        a {
          text-decoration: none;
          color: #000000;
        }
      }
      .second-container {
        display: flex;
        padding-top: 58px;
        // grid-gap: 80px;
        justify-content: center;
        a {
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }
  .warranty-rate {
    margin: 20px;
    padding-bottom: 20px;
    .first-container {
      display: flex;
      grid-gap: 80px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .sec-tabs {
    width: 220px;
  }
  .warranty-management-container {
    .diagnostics-container {
      .diagnostics-topdtc-complaints {
        padding: 30px 30px;
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .sec-tabs {
    width: 250px;
  }
  .warranty-management-container {
    .diagnostics-container {
      .diagnostics-topdtc-complaints {
        padding: 30px 60px;
      }
    }
  }
}
.wm-dashboard-middle-container {
  justify-content: center;
  display: flex;
  align-items: left;
  flex-direction: column;
  .wm-drop-down {
    padding: 20px;
  }
  .wm-table {
    width: 100%;
    margin-bottom: 30px;

    table td {
      border-bottom: 1.5px solid #c4c4c4;
      border-radius: 0px;
      border-color: #ffffff;
    }
    table th {
      color: #ffffff;
      width: auto;
      border-radius: 0px;
      background-color: #ffc000;
      border-color: #ffffff;
      span {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        cursor: pointer;
        align-items: center;
      }
    }
    table tr:nth-child(even) {
      background-color: #ffe8ca;
    }
    table tr:nth-child(odd) {
      background-color: #fff4e6;
    }
    tr:last-child td:nth-child(1) {
      border-radius: 0px !important;
    }
    tr:last-child td:last-child {
      border-radius: 0px !important;
    }
  }
}
