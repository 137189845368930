.container__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaeaea;
  padding: 0px 1px;
  border-radius: 7px;

  li {
    a {
      padding: 12px 12px;
      line-height: 46px;
      cursor: pointer;
    }
  }
  :hover {
    border-radius: 7px;
    background-color: #f4f4f4;
  }
  #{&}--previous {
    border-right: 1px solid #eaeaea;

    a {
      display: flex;
      align-items: center;
      padding: 0px 12px;
      svg {
        font-size: 1.5em;
      }
    }
    ::after {
      content: "Previous";
    }
    :hover {
      cursor: pointer;
    }
  }
  #{&}--next {
    border-left: 1px solid #eaeaea;
    a {
      display: flex;
      align-items: center;
      padding: 0px 12px;
      svg {
        font-size: 1.5em;
      }
    }
    ::before {
      content: "Next";
    }
    :hover {
      cursor: pointer;
    }
  }

  #{&}--active {
    border: 1px solid #000;
  }

  .disabled {
    color: #c4c4c4;
    pointer-events: none;
    cursor: default;
  }
}
