// .maintenance-log-hdr {
//   display: flex;
//   justify-content: space-between;
//   padding: 15px 30px;
// }

.tabs-container.maintenance-on-demand {
  margin-bottom: 30px;
}

.maintenance-log-search {
  width: 60%;
}

.maintenance-log-container {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
}
.main_on_demand .btn-secondary-blue {
  font-size: 12px !important;
  padding: 7px 10px;
}
.main_on_demand_prog .btn-secondary-blue {
  font-size: 12px !important;
  padding: 7px 10px;
}
// .page-wrapper.main_on_demand_prog .page-filter {
//   position: relative;
//   top: 10px;
//   left: 39px;
// }
.add-activity-btn {
  position: fixed;
  right: 50px;
  bottom: 30%;
  width: auto;
  padding: 10px;
  > span {
    float: left;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #fff;
    line-height: 12px;
    margin-right: 10px;
  }
}

.filter-category {
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
}

.table-buttons-container {
  justify-content: space-between;
  padding: 30px;
  .table-buttons {
    width: auto;
    padding: 5px 15px;
    &.disabled {
      opacity: 0.6;
      cursor: auto;
    }
  }
}

.showDetailsModal .modal-container {
  position: relative;
  left: 20px;
}
