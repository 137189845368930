.prognostics-based-table, .diagnostics-based-table, .usage-based-table{
    padding: 0px 12px 12px 12px;

    thead, th {
        background-color: #fff;
        color:#000;
        font-size: 14px;
        width: 16% !important; 
        padding: 10px;
        &:last-child{
            width: 20% !important;
            border-radius: 0px 20px 0px 0px !important;
        }
    }
    
    .view-details-from-table{
        cursor:pointer;
        margin-left:5px;
        text-decoration: underline;
    }

    .pagination-container{
        margin-bottom: 30px;
    }
}
.prognostics-based-table{
    margin-bottom: 15px;
}

.table-title{
    font-weight: 600;
    font-size: 16px;
    color: #112236;
    margin: 30px 0 15px 0;
    text-align: left;
}
