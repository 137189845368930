.component-system-history {
  .tabs-container {
      .tabs {
          width: 225px;
          // margin-top: 20px;
          padding:10px 5px;
          border-radius: 10px;
          margin-left: 10px;
          background: white;
          color: black;
      }
  }
}

.tabs-container-table {
  position: relative;
  right: 12px;
  display: flex;
  flex-wrap: nowrap;
  border-radius: 10px 10px 0px 0px;

  .tabs {
    width: 225px;
    // margin-top: 20px;
    padding: 10px 5px;
    border-radius: 10px;
    margin-left: 10px;
  }

  .btn-primary {
    background-color: orange !important;
  }

  .btn-secondary {
    background-color: white !important;
    color: black;
  }

  a {
    background-color: orange !important;

    .tabs {
      width: 225px;
      // margin-top: 20px;
      padding: 10px 5px;
      border-radius: 10px;
      margin-left: 10px;
      background: white;
      color: black;
    }
  }
}