@import url("./_normalize.scss");
@import url("./_common.scss");
@import url("./utlis.scss");

.container {
  padding: 20px 0px 0px;
}
.full-width {
  width: 100%;
}
.nav-content {
  width: 25%;
}

.search-field {
  min-width: 20rem;
  max-width: 20.9375em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  input {
    padding: 8px 35px 8px 20px;
    line-height: 20px;
    border-radius: 4px;
    outline: none;
    background: #fff;
    // border: 1px solid #11223699;
    border: 0.5px solid rgba(17, 34, 54, 0.6);
    &:focus {
      // border-color: #fcf1cc;
      // border-width: 2px;
      transform: 0.5s all ease;
    }
  }
}

table {
  width: 100%;
  border-spacing: 0;
  padding: 20px;
  thead,
  th {
    font-weight: 600 !important;
    font-size: 14px;
    // background-color: #fcf1cc;
    background-color: transparent;
    &:nth-child(1),
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 40%;
    }
  }
  th,
  td {
    padding: 15px;
    text-align: center;
    border-left: 1.5px solid #c4c4c4;
    border-top: 1.5px solid #c4c4c4;
    &:last-child {
      border-right: 1.5px solid #c4c4c4;
      // border-right: none;
    }
  }
  tbody tr:last-child td {
    border-bottom: 1.5px solid #c4c4c4;
  }
  tbody tr {
    &:hover {
      background-color: #f1f0f3;
      transition: 0.5s all ease;
    }
  }
  th {
    &:nth-child(1) {
      border-radius: 20px 0px 0px 0px;
    }
    &:nth-last-child(1) {
      border-radius: 0px 20px 0px 0px;
    }
  }
  thead {
    tr {
      box-shadow: 0px 1px 5px rgba(100, 116, 139, 0.12);
    }
  }
}
.content {
  width: 75%;
  padding-bottom: 50px;
  margin-left: calc(250px + 20px);
  margin-right: 2%;
  min-width: calc(100vh - 400px);
  min-height: 80vh;
  // max-height: calc(100vh - 2rem);

  &.expand {
    width: 95%;
    margin-left: 8%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
