.performance-digital-twin {
  padding: 10px;
  flex-direction: column;
  .filter-label {
    align-items: start !important;
  }
  .filter-wrapper {
    input[type="checkbox"] + span {
      top: 4px !important;
    }
  }
}

.no-data {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}

.digital-twin-charts {
  align-items: center;
  // padding: 60px;
  .large-charts {
    width: 80%;
    position: relative;
    .modal-close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
      z-index: 5;
      &.driver-behaviour {
        top: 70px;
      }
    }
    .highcharts-figure {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      margin: 0;
    }
  }
}
.digital-twin {
  .remaining-useful-life-filter {
    .select-option {
      width: 162px;
      .option-value {
        padding: 10px 13px;
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .performance-digital-twin-page-wrapper {
    padding: 30px;
  }
}
