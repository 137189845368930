@import "../../../scss/colors";

.modal-wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

@keyframes showModal {
  from {
    opacity: 0;
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animated {
  animation: showModal 0.2s forwards;
}

.close-animated {
  animation: showModal 0.2s reverse;
}

.modal-container {
  background-color: $white;
  padding: 16px;
  max-height: 80vh;
  box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
  overflow-y: auto;
  border-radius: 20px;
  &.small {
    width: 400px;
  }

  &.medium {
    width: 600px;
  }

  &.large {
    width: 698px;
    max-height: 800px;
    // padding: 30px;
    margin-left: 40px;

    .modal-header {
      //margin-bottom: 30px;
      .modal-title.center {
        // position: relative;
        display: flex;
        justify-content: center;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000;
  font-weight: 600;
}

.modal-title {
  width: 100%;
  color: $primary;
  font-size: 14px;
  font-weight: 600;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.error {
    color: $error;
  }
}

.modal-close {
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
    position: relative;
    top: 5px;
    right: 5px;
  }
}

.dtc-wrapper {
  margin-bottom: 10px;
}

.dtc-heading {
  font-size: 14px;
  color: #000;
  flex-basis: 30%;
}

.dtc-value {
  flex-basis: 70%;

  &.small {
    input {
      width: 55px;
    }
  }

  input {
    font-size: 14px;
    color: #000;
    padding: 8px 12px;
    border: 1px solid #cfcfd0;
    border-radius: 3px;
    line-height: 1px;

    &.full-width {
      width: 90%;
    }
  }
}

.dtc-button-container {
  button:first-child {
    margin-right: 24px;
    width: 60px;
    padding: 10px;
  }
}
dtc-button-container .btn-primary-blue {
  width: 180px;
}

.model-container p {
  font-size: 14px;
}

@media screen and (min-width: 1700px) {
  .model-container p {
    font-size: 16px;
  }
}

.main-diagnostics-modal {
  .modal-close {
    img {
      position: relative;
      left: 4px;
      top: 0px;
    }
  }
}

// @media screen and (max-height: 740px) {
//   .modal-container {
//     &.large {
//       width: 80%;
//     }
//   }
// }
