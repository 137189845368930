@import "../../../scss/colors";

.value-chip-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: $error;
  box-shadow: $light-gray-box-shadow;
  border-radius: 30px;
  width: fit-content;
  line-height: 14px;
  margin-right: 10px;
}
.value-chip-text {
  font-size: $font14;
  color: $secondary;
}
.value-chip-icon {
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}
