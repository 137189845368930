.notification-table {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #112236;

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    padding: 0px 20px 20px 20px;
    td {
      border: none;
      text-align: start;
    }

    th {
      // border-left: 1px solid #f7edc9;
      border: none !important;

      &:nth-child(1) {
        width: 10%;
        // border-left: 1px solid #c4c4c4;
        border: none !important;
        border-radius: 20px !important;
      }

      &:nth-child(2) {
        width: 55%;
        border: none !important;
        border-radius: 20px !important;
        justify-content: start;
        display: flex;
      }

      &:nth-child(3) {
        width: 20%;
        border: none !important;
        border-radius: 20px !important;
      }

      &:nth-child(4) {
        width: 15%;
        border: none !important;
        border-radius: 20px !important;
      }
    }

    tbody {
      tr {
        td {
          border: none;

          &:nth-child(1) {
            border-radius: 10px 0px 0px 10px !important;
            border-right: none;
          }

          &:nth-child(2) {
            border-left: none;
            border-right: none;
          }

          &:nth-child(3) {
            border-left: none;
            border-right: none;
          }

          &:last-child {
            border-radius: 0px 10px 10px 0px !important;
            border-left: none;
          }
        }
      }
    }
  }
}


.cursor-pointer {
  &.bold {
    font-weight: 600;
  }
  &.notificationType {
    background-color: #fff9f8;
    font-weight: "normal";
    cursor: pointer;
    td {
      border: 1.7px solid #dd359a;
      &:nth-child(1) {
        border-right: none;
      }
      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }
      &:nth-child(3) {
        border-left: none;
        border-right: none;
      }
      &:nth-child(4) {
        border-left: none;
      }
    }
    &.bold {
      font-weight: 600;
    }
  }
  .rightarrow {
    position: relative;
    top: 6px;
  }
}
// .notification-table-modal {
//   .modal-wrapper {
//     &.main-diagnostics-modal {
//       padding-left: 40px;
//     }
//   }
// }

@media screen and (min-width: 1400px) {
  .notification-table {
    font-size: 16px;
  }
}
